import clsx from "clsx"
import moment from "moment/moment"
import Avatar from "../../Assets/Avatar/Avatar"
import StarsRating from "../../Assets/StarsRating/StarsRating"
import { ChangeEvent, useState } from "react"
import TextArea from "../../Assets/TextArea/TextArea"
import Button from "../../Assets/Button/Button"
import styles from "./ReviewForm.module.scss"
import OrderInfoModal from "../../Modals/OrderInfoModal/OrderInfoModal"

interface Props {
  id: number
  created_at: string
  title: string
  text: string
  img: string
  rating?: number
  userName: string
  hasReview: boolean
  className?: string
}

const ReviewForm = ({ id, rating, img, userName, title, created_at, className }: Props) => {
  const [valueTxt, setValueTxt] = useState<string>("")
  const [ratingValue, setRatingValue] = useState<number>(0)
  const [openOrderModal, setOpenOrderModel] = useState<boolean>(false)

  const onSubmit = () => {
    console.info(valueTxt, ratingValue)
  }

  return (
    <>
      <div className={clsx(styles.item, className)}>
        <div className={styles.main}>
          <header className={styles.header}>
            <div className={styles.headerTop}>
              <h4 className={styles.title}>{title}</h4>
              <p className={styles.time}>{moment(created_at).format("kk:mm")}</p>
            </div>
            <div className={styles.headerBottom}>
              <div className={styles.assignee}>
                <Avatar img={img} size={"md"} className={styles.avatar} />
                <p className={styles.name}>{userName}</p>
              </div>
              <StarsRating
                rating={rating}
                name={"review-form-rate-" + id}
                onChange={(val) => {
                  setRatingValue(val)
                }}
              />
            </div>
          </header>

          <div>
            <label className={styles.label}>Оставьте комментарий</label>
            <TextArea
              autoSize
              placeholder={"Опишите как всё прошло"}
              minRows={1}
              maxRows={5}
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setValueTxt(e.target.value)}
              value={valueTxt}
              className={styles.textarea}
            />
          </div>
        </div>

        <footer className={styles.footer}>
          <Button
            txt={"Перейти в заявку"}
            mode={"white"}
            className={styles.btn}
            onClick={() => {
              setOpenOrderModel(true)
            }}
          />
          <Button
            txt={"Оставить отзыв"}
            disabled={valueTxt || ratingValue ? undefined : true}
            className={styles.btn}
            onClick={onSubmit}
          />
        </footer>
      </div>
      {/*TODO*/}
      {openOrderModal && (
        <OrderInfoModal
          chatID={"be374a2d-69f3-427f-a1c9-e5587eb515dc"}
          id={"be374a2d-69f3-427f-a1c9-e5587eb515dc"}
          open={openOrderModal}
          setOpen={setOpenOrderModel}
          layout={"history"}
        />
      )}
    </>
  )
}

export default ReviewForm
