import { FC, Fragment, ReactNode, useEffect, useMemo, useState } from "react"
import { useDispatch } from "react-redux"
import ModalPortal from "../../Assets/ModalPortal/ModalPortal"
import clsx from "clsx"
import styles from "./MobileMoreModal.module.scss"
import { getMobileMoreModalOpen, setMobileMoreModalOpen } from "../../../redux/slice/isMoreModal"
import { useAppSelector } from "../../../hooks"
import Header from "../../Header"
import Search from "../../Search/Search"
import Footer from "../../Footer"
import moment from "moment"
import { langWithCurrentMomentCode } from "../../../utils/helpers"
import { logout } from "../../../redux/slice/auth"
import { authModalOpen } from "../../../redux/slice/authModal"
import { StarIconBorder } from "../../../icons/StarIconBorder"
import { UserIcon } from "../../../icons/UserIcon"
import { languageModalOpen } from "../../../redux/slice/languageModal"
import { LANG_LIST } from "../../../utils/constants"
import { ExitIcon } from "../../../icons/ExitIcon"
import LocalizedLink from "../../../hoc/LocalizedLink"

interface listItem {
  name: string
  link?: string
  txt: string
  description?: string
  icon?: ReactNode
  func?: () => void
  classIcon?: string
  bg?: string
}

const MobileMoreModal: FC = () => {
  const [searchVal, setSearchVal] = useState<string | number>("")
  const currentLang = localStorage.getItem("i18nextLng")
  const dispatch = useDispatch()
  const mobileMoreModalIsOpen = useAppSelector(getMobileMoreModalOpen)

  const listMenu: listItem[] = useMemo(() => {
    const curLangList = LANG_LIST.find((lang) => lang.value === currentLang)

    return [
      {
        name: "reviews",
        link: "/reviews",
        txt: "Отзывы",
        description: "Нам важно ваше мнение",
        icon: <StarIconBorder />,
        func: () => {
          dispatch(setMobileMoreModalOpen(false))
        },
        bg: "yellow",
      },
      {
        name: "employees",
        link: "/employees",
        txt: "Сотрудники",
        description: "База сотрудников ВВ",
        func: () => {
          dispatch(setMobileMoreModalOpen(false))
        },
        icon: <UserIcon />,
      },
      {
        name: "language",
        txt: curLangList?.text || "Русский",
        description: "Язык интерфейса",
        icon: <img src={`/img/lang/${curLangList?.value ?? "ru"}.png`} alt="" />,
        func: () => {
          dispatch(languageModalOpen({ flag: true }))
        },
        classIcon: "lang-icon",
      },
      {
        name: "exit",
        txt: "Выход",
        description: "Из личного кабинета",
        icon: <ExitIcon />,
        func: () => {
          dispatch(logout())
          dispatch(authModalOpen({ flag: true }))
        },
        classIcon: "exit-icon",
      },
    ]
  }, [currentLang])

  useEffect(() => {
    if (currentLang) {
      moment.locale(langWithCurrentMomentCode.get(currentLang))
    } else {
      moment.locale("en")
    }
  }, [currentLang])

  const setOpenModal = () => {
    dispatch(setMobileMoreModalOpen(!mobileMoreModalIsOpen))
  }

  return (
    <ModalPortal
      isOpen={mobileMoreModalIsOpen}
      setIsOpen={setOpenModal}
      className={clsx(styles.modal, styles.MobileMoreModal)}
      isCloseBtn={false}
    >
      <div className={styles.MobileMoreModalContent}>
        <Header />
        <Search searchVal={searchVal} setSearchVal={setSearchVal} />
        <div className={clsx(styles["content-container"])}>
          <h2>Ещё</h2>
          <div className={clsx(styles["content-list"])}>
            {listMenu.map((item, i) => (
              <Fragment key={i}>
                {item.link ? (
                  <LocalizedLink
                    to={item.link}
                    className={clsx(styles["item"])}
                    onClick={() => {
                      if (item.func) item.func()
                    }}
                  >
                    <div
                      className={clsx(
                        styles["item__icon"],
                        item?.bg && styles["item__icon--" + item.bg],
                        item?.classIcon && styles[`${item.classIcon}`],
                      )}
                    >
                      {item.icon}
                    </div>
                    <div className={clsx(styles["item__content"])}>
                      <div className={clsx(styles["item__content__txt"])}>{item.txt}</div>
                      <div className={clsx(styles["item__content__desc"])}>{item.description}</div>
                    </div>
                  </LocalizedLink>
                ) : (
                  <div
                    className={clsx(styles["item"])}
                    onClick={() => {
                      if (item.func) item.func()
                    }}
                  >
                    <div
                      className={clsx(
                        styles["item__icon"],
                        item?.bg && styles["item__icon--" + item.bg],
                        item?.classIcon && styles[`${item.classIcon}`],
                      )}
                    >
                      {item.icon}
                    </div>
                    <div className={clsx(styles["item__content"])}>
                      <div className={clsx(styles["item__content__txt"])}>{item.txt}</div>
                      <div className={clsx(styles["item__content__desc"])}>{item.description}</div>
                    </div>
                  </div>
                )}
              </Fragment>
            ))}
          </div>
        </div>
        <Footer />
      </div>
    </ModalPortal>
  )
}

export default MobileMoreModal
