// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Articles_header__LmgDd,
.Articles_filter__zsJbF,
.Articles_tagsLoader__2\\+G7B,
.Articles_searchLoading__m-GRq,
.Articles_favLoading__BQbC6 {
  margin-bottom: 24px;
}

.Articles_header__LmgDd {
  justify-content: space-between;
  align-items: center;
}
@media (min-width: 1200px) {
  .Articles_header__LmgDd {
    display: flex;
  }
}

@media (min-width: 1200px) {
  .Articles_titleBack__d2HX4 {
    height: auto;
    margin: 0;
  }
}
@media (min-width: 1200px) {
  .Articles_titleBack__d2HX4 a {
    display: none;
  }
}
@media (min-width: 1200px) {
  .Articles_titleBack__d2HX4 h2 {
    margin-left: 0;
  }
}

.Articles_search__cx5OT {
  width: 100%;
}
@media (min-width: 1200px) {
  .Articles_search__cx5OT {
    max-width: 360px;
  }
}

.Articles_bottomGrid__r0K2P {
  display: grid;
  grid-template-columns: minmax(40px, auto) minmax(20px, auto);
  grid-gap: 24px;
}

.Articles_tagsLoader__2\\+G7B {
  display: flex;
  grid-gap: 8px;
}

.Articles_tagsLoaderBtn__wMNDS {
  border-radius: 47px;
  height: 32px;
  width: 60px;
}

.Articles_prevLoading__TG1EZ,
.Articles_searchLoading__m-GRq,
.Articles_favLoading__BQbC6 {
  border-radius: 12px;
}

.Articles_prevLoading__TG1EZ {
  height: 400px;
}

.Articles_searchLoading__m-GRq {
  height: 134px;
}

.Articles_favLoading__BQbC6 {
  height: 134px;
}`, "",{"version":3,"sources":["webpack://./src/components/Articles/Articles.module.scss"],"names":[],"mappings":"AAAA;;;;;EAKE,mBAAA;AACF;;AAEA;EACE,8BAAA;EACA,mBAAA;AACF;AACE;EAJF;IAKI,aAAA;EAEF;AACF;;AAEE;EADF;IAEI,YAAA;IACA,SAAA;EAEF;AACF;AACI;EADF;IAEI,aAAA;EAEJ;AACF;AAEI;EADF;IAEI,cAAA;EACJ;AACF;;AAGA;EACE,WAAA;AAAF;AAEE;EAHF;IAII,gBAAA;EACF;AACF;;AAEA;EACE,aAAA;EAGA,4DAAA;EACA,cAAA;AADF;;AAIA;EACE,aAAA;EACA,aAAA;AADF;;AAIA;EACE,mBAAA;EACA,YAAA;EACA,WAAA;AADF;;AAIA;;;EAGE,mBAAA;AADF;;AAIA;EACE,aAAA;AADF;;AAIA;EACE,aAAA;AADF;;AAIA;EACE,aAAA;AADF","sourcesContent":[".header,\r\n.filter,\r\n.tagsLoader,\r\n.searchLoading,\r\n.favLoading {\r\n  margin-bottom: 24px;\r\n}\r\n\r\n.header {\r\n  justify-content: space-between;\r\n  align-items: center;\r\n\r\n  @media (min-width: 1200px) {\r\n    display: flex;\r\n  }\r\n}\r\n\r\n.titleBack {\r\n  @media (min-width: 1200px) {\r\n    height: auto;\r\n    margin: 0;\r\n  }\r\n\r\n  a {\r\n    @media (min-width: 1200px) {\r\n      display: none;\r\n    }\r\n  }\r\n\r\n  h2 {\r\n    @media (min-width: 1200px) {\r\n      margin-left: 0;\r\n    }\r\n  }\r\n}\r\n\r\n.search {\r\n  width: 100%;\r\n\r\n  @media (min-width: 1200px) {\r\n    max-width: 360px;\r\n  }\r\n}\r\n\r\n.bottomGrid {\r\n  display: grid;\r\n  //grid-template-columns: 1fr 1fr;\r\n  //grid-template-columns: minmax(auto, 1fr) minmax(20px, auto);\r\n  grid-template-columns: minmax(40px, auto) minmax(20px, auto);\r\n  grid-gap: 24px;\r\n}\r\n\r\n.tagsLoader {\r\n  display: flex;\r\n  grid-gap: 8px;\r\n}\r\n\r\n.tagsLoaderBtn {\r\n  border-radius: 47px;\r\n  height: 32px;\r\n  width: 60px;\r\n}\r\n\r\n.prevLoading,\r\n.searchLoading,\r\n.favLoading {\r\n  border-radius: 12px;\r\n}\r\n\r\n.prevLoading {\r\n  height: 400px;\r\n}\r\n\r\n.searchLoading {\r\n  height: 134px;\r\n}\r\n\r\n.favLoading {\r\n  height: 134px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `Articles_header__LmgDd`,
	"filter": `Articles_filter__zsJbF`,
	"tagsLoader": `Articles_tagsLoader__2+G7B`,
	"searchLoading": `Articles_searchLoading__m-GRq`,
	"favLoading": `Articles_favLoading__BQbC6`,
	"titleBack": `Articles_titleBack__d2HX4`,
	"search": `Articles_search__cx5OT`,
	"bottomGrid": `Articles_bottomGrid__r0K2P`,
	"tagsLoaderBtn": `Articles_tagsLoaderBtn__wMNDS`,
	"prevLoading": `Articles_prevLoading__TG1EZ`
};
export default ___CSS_LOADER_EXPORT___;
