import { FC } from "react"
import Reviews from "../Reviews/Reviews"
import styles from "../Reviews/Reviews.module.scss"
import TitleBack from "../TitleBack/TitleBack"

const sample = [
  {
    id: 92,
    title: "Доступ к RDP",
    text: "",
    img: "/img/emptyPhoto.jpg",
    // created_at: "2024-03-18T12:39:51.000000Z",
    created_at: "2024-07-04T12:39:51.000000Z",
    userName: "Василиса Алёхина",
    hasReview: false,
  },
  {
    id: 91,
    title: "Закончились чернила в принтере",
    text: "",
    img: "/img/emptyPhoto.jpg",
    // created_at: "2024-03-18T12:39:51.000000Z",
    created_at: "2024-07-03T12:39:51.000000Z",
    userName: "Василиса Алёхина",
    hasReview: false,
  },
  {
    id: 1,
    title: "Доступ к RDP",
    text: "Спасибо, очень быстро сделали",
    img: "/img/emptyPhoto.jpg",
    rating: 4,
    // created_at: "2024-03-18T12:39:51.000000Z",
    created_at: "2024-07-03T12:39:51.000000Z",
    userName: "Василиса Алёхина",
    hasReview: true,
  },
  {
    id: 2,
    title: "Заменить монитор",
    text: "Всему свое время, и время всякой вещи под небом: время рождаться и время умирать…время разрушать, и время строить… время разбрасывать камни, и время собирать камни; время обнимать, и время уклоняться от объятий… время любить, и время ненавидеть; время войне, и время миру.(Еккл. 3. 1-2, 5, 8)",
    img: "",
    rating: 1,
    created_at: "2024-03-18T10:39:51.000000Z",
    userName: "Альберт Новиков",
    hasReview: true,
  },
  {
    id: 3,
    title: "Доступ в 1С",
    text: "",
    img: "",
    rating: 5,
    created_at: "2024-03-18T12:39:51.000000Z",
    userName: "Петр Пшеничный",
    hasReview: true,
  },
  {
    id: 4,
    title: "Выдача ноутбука",
    text: "",
    img: "",
    rating: 2,
    created_at: "2024-01-16T09:20:51.000000Z",
    userName: "Максим Хоренко",
    hasReview: true,
  },
]

const ReviewsLayout: FC = () => {
  return (
    <>
      <TitleBack title={"Мои отзывы"} />
      <Reviews list={sample} />
    </>
  )
}

export default ReviewsLayout
