import { FC, RefObject } from "react"
import clsx from "clsx"
import { ArrowRightIcon } from "../../../icons/ArrowRightIcon"
import styles from "./SliderNav.module.scss"

interface Props {
  className?: string
  mode?: "white"
  prevRef?: RefObject<HTMLButtonElement>
  nextRef?: RefObject<HTMLButtonElement>
}

const SliderNav: FC<Props> = ({ className, mode, prevRef, nextRef }) => {
  return (
    <div className={clsx("slider-nav", styles.nav, className)}>
      <button
        ref={prevRef}
        className={clsx("slider-prev-btn", styles.btn, styles["btn--prev"], mode && styles["btn--" + mode])}
      >
        <ArrowRightIcon />
      </button>
      <button ref={nextRef} className={clsx("slider-next-btn", styles.btn, mode && styles["btn--" + mode])}>
        <ArrowRightIcon />
      </button>
    </div>
  )
}

export default SliderNav
