import LocalizedLink from "../../hoc/LocalizedLink"
import { ArrowLeftBoldIcon } from "../../icons/ArrowLeftBoldIcon"
import { useGetServiceListQuery } from "../../redux/api/main"
import OrderLayoutHeader from "./OrderLayoutHeader"
import OrderLayoutMain from "./OrderLayoutMain"
import orderServiceStyles from "../OrderService/OrderService.module.scss"
import { useGetServiceOrderQuery } from "../../redux/api/content"
import clsx from "clsx"
import styles from "./OrderLayout.module.scss"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

const OrderLayout = ({ id }: { id: string }) => {
  const navigate = useNavigate()

  const { data: order, isLoading: isLoadingProduct, isUninitialized } = useGetServiceOrderQuery(id)
  const { data: serviceList, isLoading: isLoadingList, isUninitialized: isUninitializedList } = useGetServiceListQuery()

  useEffect(() => {
    if (isUninitialized && isUninitializedList && !order) {
      navigate("/")
    }
  }, [isUninitialized, isUninitializedList, order])

  return (
    <>
      {isLoadingList || isLoadingProduct ? (
        <div className={clsx("skeletonBlock", orderServiceStyles.linkLoader)} />
      ) : (
        <LocalizedLink to={`/products/${order?.category_id}`} className={orderServiceStyles.link}>
          <ArrowLeftBoldIcon />
          {serviceList?.find((service) => service.id === order?.category_id)?.name}
        </LocalizedLink>
      )}

      {isLoadingProduct ? (
        <>
          <div className={clsx("skeletonBlock", styles.headerLoader)} />
          <div className={clsx("skeletonBlock", styles.mainLoader)} />
        </>
      ) : (
        <>
          {order?.id && (
            <>
              <OrderLayoutHeader order={order} />
              <OrderLayoutMain order={order} />
            </>
          )}
        </>
      )}
    </>
  )
}

export default OrderLayout
