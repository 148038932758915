import { FC } from "react"
import styles from "../RadioRow/RadioRow.module.scss"
import clsx from "clsx"

interface Props {
  name: string
  title?: string
  value: any
  onChange: any
  checked: any
  error?: boolean
  className?: string
}

const CheckBoxRow: FC<Props> = ({ name, title, value, onChange, checked, error, className }) => {
  return (
    <label className={clsx(styles.radioRow, className)}>
      <input type="checkbox" name={name} onChange={onChange} value={value ?? ""} checked={checked} hidden />
      <div className={`${styles.radioRow__itemCheck} ${error ? `${styles.error} error` : ""}`}>
        <img src="/img/check.svg" alt="" />
      </div>
      <p className={styles.radioRow__itemText}>{title}</p>
    </label>
  )
}

export default CheckBoxRow
