import { FC, useRef, useState } from "react"
import clsx from "clsx"
import { useOnClickOutside } from "../../../hooks/useOnClickOutside"
import { ArrowDownIcon } from "../../../icons/ArrowDownIcon"
import styles from "./SimpleSelect.module.scss"

type selectVal = { value: string; label: string; leftIcon?: string }

interface Props {
  value: string
  list: selectVal[]
  type?: "lang"
  onChange?: (e: selectVal) => void
  className?: string
}

const SimpleSelect: FC<Props> = ({ value, list, type, onChange, className }) => {
  const selectRef = useRef<HTMLDivElement>(null)
  const [isOpen, setOpen] = useState<boolean>(false)
  const [val, setVal] = useState<string>(value)

  const handleSelect = () => {
    setOpen((prev) => !prev)
  }

  const clickItem = (item: selectVal) => {
    setVal(item.value)
    handleSelect()
    if (onChange) onChange(item)
  }

  useOnClickOutside(selectRef, () => {
    if (isOpen) handleSelect()
  })

  return (
    <div
      ref={selectRef}
      className={clsx(styles.select, isOpen && styles["select--is-open"], type && styles["select--" + type], className)}
    >
      <button className={styles.btn} onClick={handleSelect}>
        {type === "lang" ? (
          <img src={`/img/lang/${list?.find((item) => item.value === val)?.value}.png`} alt={""} />
        ) : (
          list?.find((item) => item.value === val)?.label || "Выберите"
        )}
        <span className={styles.iconWrap}>
          <ArrowDownIcon />
        </span>
      </button>
      {isOpen && (
        <div className={clsx(styles.drop, "select__drop")}>
          <ul className={styles.list}>
            {list?.map((item) => {
              return (
                <li key={item.value} className={styles.listItem}>
                  <button onClick={() => clickItem(item)} className={clsx(styles.item)}>
                    {item?.leftIcon && (
                      <span className={styles.leftIcon}>
                        <img src={item.leftIcon} alt={item.value} />
                      </span>
                    )}
                    {item.label}
                  </button>
                </li>
              )
            })}
          </ul>
        </div>
      )}
    </div>
  )
}

export default SimpleSelect
