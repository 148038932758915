// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MobileMenu_mobile-menu__lMQY- {
  position: fixed;
  display: flex;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #FFF;
  border-top: 1px solid var(--gray-100);
  height: 66px;
  z-index: 102;
}
.MobileMenu_mobile-menu__lMQY- .MobileMenu_item__-7s0y {
  position: relative;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  cursor: pointer;
  width: 100%;
}
.MobileMenu_mobile-menu__lMQY- .MobileMenu_item__icon__GxwVh {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
}
.MobileMenu_mobile-menu__lMQY- .MobileMenu_item__name__rz3aU {
  font-size: 10px;
}
.MobileMenu_mobile-menu__lMQY- .MobileMenu_is-active__K9RXC .MobileMenu_item__name__rz3aU {
  color: var(--accent-color);
}
.MobileMenu_mobile-menu__lMQY- .MobileMenu_is-active__K9RXC .MobileMenu_item__icon__GxwVh svg {
  fill: var(--accent-color);
}
.MobileMenu_mobile-menu__lMQY- .MobileMenu_is-active__K9RXC .MobileMenu_stroke-icon__dOgwh svg {
  fill: none;
  stroke: var(--accent-color);
}
.MobileMenu_mobile-menu__lMQY- .MobileMenu_is-active__K9RXC .MobileMenu_circle-icon__RIfhn svg circle {
  fill: var(--accent-color);
}
.MobileMenu_mobile-menu__lMQY- .MobileMenu_is-active__K9RXC::after {
  content: "";
  position: absolute;
  top: 0;
  height: 2px;
  background: var(--accent-color);
  width: calc(100% - 28px);
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
}`, "",{"version":3,"sources":["webpack://./src/components/MobileMenu/MobileMenu.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,aAAA;EACA,SAAA;EACA,OAAA;EACA,WAAA;EACA,gBAAA;EACA,qCAAA;EACA,YAAA;EACA,YAAA;AACF;AACE;EACE,kBAAA;EACA,aAAA;EACA,YAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,QAAA;EACA,eAAA;EACA,WAAA;AACJ;AACI;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,WAAA;EACA,YAAA;AACN;AAEI;EACE,eAAA;AAAN;AAKI;EACE,0BAAA;AAHN;AAOM;EACE,yBAAA;AALR;AAUM;EACE,UAAA;EACA,2BAAA;AARR;AAcQ;EACE,yBAAA;AAZV;AAiBI;EACE,WAAA;EACA,kBAAA;EACA,MAAA;EACA,WAAA;EACA,+BAAA;EACA,wBAAA;EACA,8BAAA;EACA,+BAAA;AAfN","sourcesContent":[".mobile-menu {\n  position: fixed;\n  display: flex;\n  bottom: 0;\n  left: 0;\n  width: 100%;\n  background: #FFF;\n  border-top: 1px solid var(--gray-100);\n  height: 66px;\n  z-index: 102;\n\n  .item {\n    position: relative;\n    display: flex;\n    flex-grow: 1;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    gap: 4px;\n    cursor: pointer;\n    width: 100%;\n\n    &__icon {\n      display: flex;\n      align-items: center;\n      justify-content: center;\n      width: 24px;\n      height: 24px;\n    }\n  \n    &__name {\n      font-size: 10px;\n    }\n  }\n\n  .is-active {\n    .item__name {\n      color: var(--accent-color);\n    }\n\n    .item__icon {\n      svg {\n        fill: var(--accent-color);\n      }\n    }\n\n    .stroke-icon {\n      svg {\n        fill: none;\n        stroke: var(--accent-color);\n      }\n    }\n\n    .circle-icon {\n      svg {\n        circle {\n          fill: var(--accent-color);\n        }\n      }\n    }\n\n    &::after {\n      content: '';\n      position: absolute;\n      top: 0;\n      height: 2px;\n      background: var(--accent-color);\n      width: calc(100% - 28px);\n      border-bottom-left-radius: 50%;\n      border-bottom-right-radius: 50%;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mobile-menu": `MobileMenu_mobile-menu__lMQY-`,
	"item": `MobileMenu_item__-7s0y`,
	"item__icon": `MobileMenu_item__icon__GxwVh`,
	"item__name": `MobileMenu_item__name__rz3aU`,
	"is-active": `MobileMenu_is-active__K9RXC`,
	"stroke-icon": `MobileMenu_stroke-icon__dOgwh`,
	"circle-icon": `MobileMenu_circle-icon__RIfhn`
};
export default ___CSS_LOADER_EXPORT___;
