import styles from "./Review.module.scss"
import clsx from "clsx"
import Avatar from "../Assets/Avatar/Avatar"
import StarsRating from "../Assets/StarsRating/StarsRating"
import moment from "moment"

interface Props {
  id: number
  created_at: string
  title: string
  text: string
  img: string
  rating?: number
  userName: string
  hasReview: boolean
  className?: string
}

const Review = ({ id, title, text, created_at, userName, img, rating, className }: Props) => {
  return (
    <div className={clsx(styles.item, className)}>
      <h4 className={styles.title}>{title}</h4>
      {text && <p className={styles.txt}>{text}</p>}
      <div className={styles.rateSection}>
        <Avatar img={img} size={"sm"} className={styles.avatar} />
        <div>
          <p className={styles.name}>{userName}</p>
          <StarsRating rating={rating} size={"sm"} name={"review-rate-" + id} disabled />
        </div>
        <p className={styles.time}>{moment(created_at).format("kk:mm")}</p>
      </div>
    </div>
  )
}

export default Review
