import { FC } from "react"
import { useAppSelector } from "../../../hooks"
import { selectSupportAuth } from "../../../redux/slice/auth"
import Label from "../../Assets/Label/Label"
import styles from "./FooterSupport.module.scss"
import { TelegramIcon } from "../../../icons/telegramIcon"
import { MailIcon } from "../../../icons/mailIcon"
import { GlobeLightIcon } from "../../../icons/GlobeLightIcon"
import { PhoneIcon } from "../../../icons/phoneIcon"

const FooterSupport: FC = () => {
  const { site, email, phone, telegram } = useAppSelector(selectSupportAuth)

  return (
    <div className={`support ${styles.support}`}>
      {telegram && (
        <Label
          className={styles.label}
          txt={telegram}
          href={`https://t.me/${telegram}`}
          icon={<TelegramIcon />}
          isTarget
        />
      )}
      {email && <Label className={styles.label} txt={email} href={`mailto:${site}`} icon={<MailIcon />} />}
      {site && (
        <Label className={styles.label} txt={site} href={`https://${site}`} icon={<GlobeLightIcon />} isTarget />
      )}
      {phone && <Label className={styles.label} txt={phone} href={`tel:${phone}`} icon={<PhoneIcon />} />}
    </div>
  )
}

export default FooterSupport
