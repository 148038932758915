import Layout from "../../components/Layout/Layout"
import OrderLayout from "../../components/OrderLayout/OrderLayout"
import { useNavigate, useParams } from "react-router-dom"

export default function OrderInfoPage() {
  const { id } = useParams()
  const navigate = useNavigate()

  if (!id) navigate("/")

  return <Layout isHeaderMobHidden>{id && <OrderLayout id={id} />}</Layout>
}
