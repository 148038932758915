import { Dispatch, FC, SetStateAction, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import TextareaAutosize from "react-textarea-autosize"
import { useLocation } from "react-router-dom"
import {
  usePostOrderReviewMutation,
  usePostOrderModalWasOpenedMutation,
  useGetQuantityOrderWithoutReviewMutation,
  useLazyGetServiceOrderQuery,
} from "../../../redux/api/content"
import HistoryOrdModalSkeleton from "../HistoryOrdModal/HistoryOrdModalSkeleton"
import StarsRating from "../../Assets/StarsRating/StarsRating"
import { useDispatch } from "react-redux"
import { changeOrderHasRevied } from "../../../redux/slice/ordersList"
import { updateCountOrdersWithoutReview } from "../../../redux/slice/main"
import { getApiUrl } from "../../../utils/getApiUrl"
import Button from "../../Assets/Button/Button"
import ModalPortal from "../../Assets/ModalPortal/ModalPortal"
import clsx from "clsx"
import Avatar from "../../Assets/Avatar/Avatar"
import { useScrollBlock } from "../../../hooks/useScrollBlock"
import styles from "./RatingModal.module.scss"

interface IRatingModal {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  orderId: string
}

const RatingModal: FC<IRatingModal> = ({ orderId, open, setOpen }) => {
  const [step, setStep] = useState<number>(1)
  const [comment, setComment] = useState<string>("")
  const [rating, setRating] = useState<number>(0)
  const [isLoading, setIsLoading] = useState(false)
  const [getServiceOrder] = useLazyGetServiceOrderQuery()
  const [orderInfo, setOrderInfo] = useState<any>(null)

  const [postOrderModalWasOpened] = usePostOrderModalWasOpenedMutation()
  const [getQuantityOrderWithoutReview] = useGetQuantityOrderWithoutReviewMutation()

  const [postOrderReview] = usePostOrderReviewMutation()

  const dispatch = useDispatch()
  const location = useLocation()
  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  const { allowScroll } = useScrollBlock()

  useEffect(() => {
    location.pathname.includes("history") ? setStep(1) : setStep(2)
    setIsLoading(true)
    getServiceOrder(orderId)
      .then((result) => {
        setOrderInfo(result?.data)
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false))
    // отправка события об открытии модалки
    postOrderModalWasOpened(orderId)
  }, [])

  const doClose = () => {
    setOpen(false)
    allowScroll(true)
  }

  const changeRatingReviewHandler = (value: any) => {
    setRating(value)
  }

  const submitForm = () => {
    const data = { id: orderId, textReview: comment, ratingReview: rating }
    setIsLoading(true)
    postOrderReview(data)
      .then(() => {
        setStep(3)
        dispatch(changeOrderHasRevied({ id: orderId, hasReview: true }))
        setComment("")
      })
      .then(() => {
        getQuantityOrderWithoutReview().then((res: any) => {
          dispatch(updateCountOrdersWithoutReview(res))
        })
      })
      .catch((er) => console.warn(er.message))
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <ModalPortal
      isOpen={open}
      setIsOpen={setOpen}
      className={clsx(styles.modal, styles["modal--step-" + step], isLoading && styles["modal--is-loading"])}
      header={
        isLoading ? undefined : step === 1 ? (
          <h2 className={clsx("modal__title", styles.title)}>{t("evaluateTaskTitle")}</h2>
        ) : step === 2 ? (
          <>
            <Avatar
              isOrder
              img={orderInfo?.photos?.[0] ? getApiUrl() + "/public/photo/preview/" + orderInfo?.photos?.[0] : undefined}
            />

            <div className={styles.titleWrap}>
              <p>{t("serviceCompleted")}</p>
              <h2 className={clsx("modal__title", styles.title)}>
                {orderInfo?.name} {orderInfo?.count > 1 && <span> x{orderInfo?.count}</span>}
              </h2>
            </div>
          </>
        ) : undefined
      }
    >
      {isLoading ? (
        <HistoryOrdModalSkeleton />
      ) : (
        <div className={styles.RatingModal}>
          {step === 1 ? (
            <>
              <div className={styles.RatingModal__block}>
                <div className={styles.RatingModal__executorBlockDescr}>
                  <div className={styles.RatingModal__executorTitle}>{t("executor")}</div>
                  <div className={styles.RatingModal__executorName}>
                    {(orderInfo && orderInfo?.assignees[0]?.name) || t("executorNotFound")}
                  </div>
                </div>
                <div className={`${styles.RatingModal__blockImg} ${styles.RatingModal__blockImg_small}`}>
                  <img
                    src={
                      orderInfo?.assignees[0]?.avatar_id
                        ? getApiUrl() + "/web/user/avatar/" + orderInfo?.assignees[0]?.avatar_id
                        : "/img/productNoImg.svg"
                    }
                    alt="avatar"
                  />
                </div>
              </div>
              <div className={styles.RatingModal__ratingBlock}>
                <div className={styles.RatingModal__ratingTitle}>{t("rating")}</div>
                <StarsRating name="rating" onChange={changeRatingReviewHandler} />
              </div>
              <div className={`modal-input ${styles.RatingModal__input}`}>
                <div className={`input-label ${styles.RatingModal__ratingTitle}`}>{t("review")}</div>
                <TextareaAutosize
                  minRows={1}
                  maxRows={5}
                  placeholder={t("yourReview")}
                  onChange={(e) => setComment(e.target.value)}
                  value={comment}
                />
              </div>
              <div className={`${styles.RatingModal__bottom}`}>
                <Button txt={t("back")} onClick={doClose} mode={"gray"} />
                <Button txt={t("send")} disabled={!rating} onClick={submitForm} />
              </div>
            </>
          ) : step === 2 ? (
            <>
              <div className={styles.RatingModal__block}>
                <div className={styles.RatingModal__executorBlockDescr}>
                  <div className={styles.RatingModal__executorTitle}>{t("executor")}</div>
                  <div className={styles.RatingModal__executorName}>
                    {orderInfo?.assignees[0]?.name || t("executorNotFound")}
                  </div>
                </div>
                <div className={`${styles.RatingModal__blockImg} ${styles.RatingModal__blockImg_small}`}>
                  <img
                    src={
                      orderInfo?.assignees[0]?.avatar_id
                        ? getApiUrl() + "/web/user/avatar/" + orderInfo?.assignees[0]?.avatar_id
                        : "/img/productNoImg.svg"
                    }
                    alt="avatar"
                  />
                </div>
              </div>
              <div className={styles.RatingModal__ratingBlock}>
                <div className={styles.RatingModal__ratingTitle}>{t("rating")}</div>
                <StarsRating name="rating" onChange={changeRatingReviewHandler} />
              </div>
              <div className={`modal-input ${styles.RatingModal__input}`}>
                <div className={`input-label ${styles.RatingModal__ratingTitle}`}>{t("review")}</div>
                <TextareaAutosize
                  minRows={1}
                  maxRows={5}
                  placeholder={t("yourReview")}
                  onChange={(e) => setComment(e.target.value)}
                  value={comment}
                />
              </div>

              <div className={`${styles.RatingModal__bottom}`}>
                <Button txt={t("skip")} mode={"gray"} onClick={doClose} />
                <Button txt={t("evaluate")} disabled={!rating} onClick={submitForm} className={styles.submitBtn} />
              </div>
            </>
          ) : (
            <>
              <div className={styles.RatingModal__title}>{t("thanksReviewTitle")}</div>
              <div className={styles.RatingModal__descrBlock}>
                <div className={styles.RatingModal__descr}>{t("thanksReviewCommonDescr")}</div>
              </div>
              <Button txt={t("itIsClear")} onClick={doClose} size={"md"} />
            </>
          )}
        </div>
      )}
    </ModalPortal>
  )
}

export default RatingModal
