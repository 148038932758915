import { FC, useEffect, useState } from "react"
import styles from "./OrderItem.module.scss"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { getApiUrl } from "../../utils/getApiUrl"
import { IServiceOrderShort } from "../../types/content"
import moment from "moment/moment"
import clsx from "clsx"
import { WorkListIcon } from "../../icons/WorkListIcon"
import { ArrowBoldRightIcon } from "../../icons/ArrowBoldRightIcon"

interface Props extends IServiceOrderShort {
  clickFcn?: () => void
  ratingFn?: () => void
  layout?: "history" | "active"
}

const OrderItem: FC<Props> = ({
  photos,
  name,
  path_name,
  created_at,
  clickFcn,
  count,
  layout,
  ratingFn,
  id,
  status,
  message_counts,
}) => {
  const { t } = useTranslation("translation", { keyPrefix: `interface` })

  const [wasReview, setWasReview] = useState<boolean>(false)

  const { ordersList } = useSelector((state: any) => state.ordersList)

  useEffect(() => {
    const order = ordersList.find((el: any) => el.id === id)
    if (order) {
      setWasReview(order.hasReview)
    }
  })

  return (
    <div className={styles.orderItem} onClick={clickFcn}>
      <div className={styles.inner}>
        <div className={clsx(styles.imgWrap, !photos?.length && styles["imgWrap--empty"])}>
          {photos?.length > 0 ? (
            <img src={getApiUrl() + "/public/photo/preview/" + photos[0]} alt="" />
          ) : (
            <WorkListIcon />
          )}
        </div>
        <div className={styles.info}>
          <p className={styles.name}>
            {name} {count > 1 ? <span>x{count}</span> : null}
          </p>
          <span className={styles.description}>{path_name}</span>
          {status === -1 && <div className={styles.canceled}>{t("canceledOrder")}</div>}
          {layout !== "active" && ratingFn && !wasReview && status === 1 && (
            <button
              className={styles.ratingBtn}
              onClick={(e) => {
                e.stopPropagation()
                ratingFn()
              }}
            >
              {t("giveRating")}{" "}
              <span className={styles.ratingBtn__iconWrap}>
                <ArrowBoldRightIcon />
              </span>
            </button>
          )}
        </div>
        <div className={styles.time}>{moment(created_at).format("kk:mm")}</div>
      </div>
      {!!message_counts && layout !== "history" && <span className={`${styles.message}`}>{message_counts}</span>}
    </div>
  )
}

export default OrderItem
