// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OrderCallbackModal_txt__4AVXq {
  font-size: var(--fz-sm);
  line-height: var(--lh-sm);
  color: #54575C;
  margin-bottom: 8px;
}

.OrderCallbackModal_btn__qricL {
  display: flex;
  margin-left: auto;
  padding-left: 42px;
  padding-right: 42px;
  margin-top: 12px;
}`, "",{"version":3,"sources":["webpack://./src/components/Modals/OrderInfoModal/OrderCallbackModal.module.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;EACA,yBAAA;EACA,cAAA;EACA,kBAAA;AACF;;AAEA;EACE,aAAA;EACA,iBAAA;EACA,kBAAA;EACA,mBAAA;EACA,gBAAA;AACF","sourcesContent":[".txt {\r\n  font-size: var(--fz-sm);\r\n  line-height: var(--lh-sm);\r\n  color: #54575C;\r\n  margin-bottom: 8px;\r\n}\r\n\r\n.btn {\r\n  display: flex;\r\n  margin-left: auto;\r\n  padding-left: 42px;\r\n  padding-right: 42px;\r\n  margin-top: 12px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"txt": `OrderCallbackModal_txt__4AVXq`,
	"btn": `OrderCallbackModal_btn__qricL`
};
export default ___CSS_LOADER_EXPORT___;
