import { FC, useEffect } from "react"
import styles from "./ChatModal.module.scss"
import Chat from "../../Chat/Chat"
import Modal from "../../Modal/Modal"
import { useDispatch } from "react-redux"
import { addAllMessage } from "../../../redux/slice/main"
import { useAppSelector } from "../../../hooks"
import { selectOpenOrder } from "../../../redux/slice/ordersList"

interface Props {
  chatID: string
  openChat: boolean
  setOpenChat: any
  orderInfo: any
  isFirstLoadMess: boolean
}

const ChatModal: FC<Props> = ({ openChat, chatID, setOpenChat, orderInfo, isFirstLoadMess }) => {
  const dispatch = useDispatch()
  const openOrder = useAppSelector(selectOpenOrder)

  useEffect(() => {
    if (typeof window.Echo !== "undefined" && chatID) {
      const channel = window.Echo.join(`chats.${chatID}`)

      channel.listen(".client-addMessage", (event: any) => {
        dispatch(addAllMessage(event))
      })
    }
  }, [openChat, openOrder])

  return (
    <div className={styles.chatModal__wp}>
      <Modal open={openChat} setOpen={setOpenChat} addClass={"chat-m"}>
        <Chat chatID={chatID} orderInfo={orderInfo} isFirstLoadMess={isFirstLoadMess} setOpenChat={setOpenChat} />
      </Modal>
    </div>
  )
}

export default ChatModal
