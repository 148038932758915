import { useEffect, useMemo, useState, useRef, FC } from "react"
import TitleBack from "../TitleBack/TitleBack"
import Search from "../Search/Search"
import SearchList from "../SearchList/SearchList"
import { useParams } from "react-router-dom"
import { useGetProductsListMutation, useGetServiceInfoMutation } from "../../redux/api/main"
import { updateProductsList, setSubcatsList, resetSubcatsList } from "../../redux/slice/products"
import { useAppDispatch, useAppSelector } from "../../hooks"
import { updateIsAllowNewServiceCategory } from "../../redux/slice/auth"
import { useGetRoomSubcatListMutation } from "../../redux/api/main"
import { Swiper, SwiperSlide } from "swiper/react"
import { SwiperOptions } from "swiper/types/swiper-options"
import { FreeMode, Navigation } from "swiper/modules"
import styles from "../RoomLayout/RoomLayout.module.scss"
import { useTranslation } from "react-i18next"
import SliderNav from "../Assets/SliderNav/SliderNav"
import useWindowSize from "../../hooks/useWindowSize"
import clsx from "clsx"
import SearchListLoader from "../SearchList/SearchListLoader"

interface Props {}

const swiperParams: SwiperOptions = {
  slidesPerView: "auto",
  modules: [FreeMode, Navigation],
  navigation: {
    prevEl: `.products-layout-slider-nav .slider-prev-btn`,
    nextEl: `.products-layout-slider-nav .slider-next-btn`,
  },
  freeMode: true,
  observer: true,
  observeParents: true,
}

const ProductsLayout: FC<Props> = () => {
  const [searchVal, setSearchVal] = useState<string | number>("")
  const [pageTitle, setPageTitle] = useState<string>("")
  const [numActiveLink, setNumActiveLink] = useState<null | number>(0)
  const [servicesFromCategory, setServicesFromCategory] = useState<any>(null)
  const [isSliderWrapFixed, setSliderWrapFixed] = useState<boolean>(false)

  const swiperRef = useRef<any>(null)
  const catList = useRef<any>(null)
  const catSliderWrapRef = useRef<HTMLDivElement>(null)
  const serviceItemsContainer = useRef<null | NodeListOf<HTMLElement>>(null)

  const { t } = useTranslation("translation", { keyPrefix: `interface` })

  const [getServiceInfo] = useGetServiceInfoMutation()
  const [getProductsList, { isLoading }] = useGetProductsListMutation()
  const dispatch = useAppDispatch()
  const { isDesktop } = useWindowSize()

  const queries = useParams()

  const { productsList, productsListLoading, subCatsList } = useAppSelector((state) => state.products)

  const [getRoomSubcatList] = useGetRoomSubcatListMutation()

  useEffect(() => {
    if (subCatsList?.length > 0 && !searchVal)
      serviceItemsContainer.current = catList?.current?.querySelectorAll(`.searchList-item`)
  }, [subCatsList, serviceItemsContainer, searchVal])

  const scrollToItem = (num: number) => {
    const heightOfHeader = window.innerWidth > 640 ? 112 : 70

    if (serviceItemsContainer?.current && serviceItemsContainer.current[num]) {
      const scrollToo = serviceItemsContainer?.current[num].offsetTop - heightOfHeader
      window.scrollTo(0, scrollToo)
    }
  }

  const scrollTrigger = () => {
    const scrollMenu = document.querySelector<HTMLElement>(`.${styles.roomLayout__cats}`)
    const windowWidth = window.innerWidth

    const serviceNodeList = serviceItemsContainer.current
    const heightOfHeader = windowWidth > 640 ? 112 : 70

    serviceNodeList?.forEach((el, i) => {
      const sw = swiperRef.current.swiper
      // - 1 слайд при перключении
      if (window.scrollY + heightOfHeader > el.offsetTop - 5 && window.scrollY < el.offsetTop + el.offsetHeight) {
        setNumActiveLink(i)
        sw.slideTo(i)
      } else if (
        window.scrollY < serviceNodeList[0].offsetTop ||
        window.scrollY >
          serviceNodeList[serviceNodeList.length - 1].offsetTop +
            serviceNodeList[serviceNodeList.length - 1].offsetHeight
      )
        setNumActiveLink(0)
    })

    //меню с ссылками
    if (scrollMenu && window.innerWidth < 1200) {
      const valuePx = windowWidth <= 640 ? 110 - window.scrollY : 166 - window.scrollY
      if ((windowWidth <= 640 && valuePx <= 0) || (windowWidth > 640 && valuePx <= 0)) {
        scrollMenu.style.top = `${windowWidth <= 640 ? 0 : 0}px`
        scrollMenu.style.borderBottom = "1px solid #b3b8bb"
        return
      } else {
        scrollMenu.style.top = `${valuePx}px`
        scrollMenu.style.borderBottom = "none"
      }
    }
    const top = catSliderWrapRef.current?.getBoundingClientRect().top
    if (top && window.innerWidth >= 1200) setSliderWrapFixed(top <= 64)
  }

  useEffect(() => {
    document.addEventListener("scroll", scrollTrigger)
    document.addEventListener("resize", scrollTrigger)
    scrollTrigger()

    return () => {
      document.removeEventListener("scroll", scrollTrigger)
      document.removeEventListener("resize", scrollTrigger)
      dispatch(resetSubcatsList())
      setServicesFromCategory(null)
    }
  }, [])

  useEffect(() => {
    const catObj = queries?.id ? { categories: [queries.id] } : undefined
    // Получание списка услуг
    getProductsList({ ...catObj })
      .then((result: any) => {
        dispatch(updateProductsList({ list: result?.data?.data?.aItems ?? [] }))
      })
      .catch((err: any) => console.log(err))
    getServiceInfo(queries?.id ?? "")
      .then((result: any) => {
        setPageTitle(result?.data?.data?.name ?? "")
        dispatch(updateIsAllowNewServiceCategory(result?.data?.data?.is_allow_prorose_new_service))
      })
      .catch((err: any) => console.log(err))
    // получение списка подкатегорий
    getRoomSubcatList({ category_id: queries?.id }).then((res: any) => dispatch(setSubcatsList(res)))

    window.scrollTo(0, 0)
  }, [])

  const resultSearchList = useMemo(
    () => productsList.filter(({ name }: any) => name?.toLowerCase().includes(String(searchVal).toLowerCase())),
    [searchVal, productsList],
  )

  // Категория для сервисов без категории
  const withoutCats = useMemo(() => {
    let freeElements: any = []
    subCatsList.forEach(({ services }: any) => {
      if (typeof services === "object") {
        freeElements = [...freeElements, ...services.map((sr: any) => sr.id)]
      }
    })
    return productsList.filter(({ id }: any) => !freeElements?.includes(id))
  }, [subCatsList, productsList])

  return (
    <>
      <TitleBack title={pageTitle} />
      <Search searchVal={searchVal} setSearchVal={setSearchVal} isLoading={isLoading} />
      <div
        style={{ display: subCatsList?.length && !searchVal ? "block" : "none" }}
        className={clsx(styles.roomLayout__cats, isSliderWrapFixed && styles["roomLayout__cats--fixed"])}
        ref={catSliderWrapRef}
      >
        <div className={styles.roomLayout__catsWp}>
          <div className={styles.roomLayout__catsWrap}>
            {!isLoading && (
              <>
                <Swiper {...swiperParams} ref={swiperRef}>
                  {subCatsList?.map((el: any, kk: number) => (
                    <SwiperSlide
                      onClick={() => scrollToItem(kk)}
                      key={el.id}
                      className={`${styles["roomLayout__catsWrap-anchor"]} ${
                        numActiveLink === kk ? `${styles["roomLayout__catsWrap-anchor-active"]}` : ""
                      }`}
                    >
                      <div>{el.name}</div>
                    </SwiperSlide>
                  ))}
                  {!!withoutCats.length && (
                    <SwiperSlide
                      onClick={() => scrollToItem(subCatsList.length)}
                      className={`${styles["roomLayout__catsWrap-anchor"]} ${
                        numActiveLink === subCatsList.length ? `${styles["roomLayout__catsWrap-anchor-active"]}` : ""
                      }`}
                    >
                      <div>{t("other")}</div>
                    </SwiperSlide>
                  )}
                </Swiper>
                {isDesktop && <SliderNav className={"products-layout-slider-nav"} />}
              </>
            )}
          </div>
        </div>
      </div>

      <div
        style={{
          marginTop:
            subCatsList?.length > 0 && !searchVal && window.innerWidth < 1200
              ? window.innerWidth > 640
                ? 100
                : 70
              : 0,
        }}
        ref={catList}
      >
        {isLoading ? (
          <SearchListLoader />
        ) : (
          <>
            {subCatsList?.length > 0 && !searchVal ? (
              <>
                {subCatsList?.map((subCat: any, kk: number) => (
                  <SearchList
                    isSearchLoading={isLoading}
                    key={subCat?.id}
                    title={subCat?.name ?? ""}
                    list={subCat?.services}
                    catId={subCat?.id}
                    className={styles.roomLayout__searchList}
                  />
                ))}
                {!!withoutCats.length && (
                  <SearchList
                    isSearchLoading={isLoading}
                    title={t("other")}
                    list={withoutCats}
                    catId={"otherCategory"}
                    className={styles.roomLayout__searchList}
                  />
                )}
              </>
            ) : (
              <SearchList list={resultSearchList} isSearchLoading={isLoading} searchVal={searchVal} />
            )}
          </>
        )}
      </div>
    </>
  )
}

export default ProductsLayout
