import { FC, Fragment, useMemo, useState } from "react"
import clsx from "clsx"
import { IEmployee } from "../../../types/content"
import { getApiUrl } from "../../../utils/getApiUrl"
import Avatar from "../../Assets/Avatar/Avatar"
import { ChatIcon } from "../../../icons/ChatIcon"
import MessagesModal from "../../Modals/MessagesModal/MessagesModal"
import { useCreateChatMutation } from "../../../redux/api/chat"
import BtnLoader from "../../Assets/BtnLoader/BtnLoader"
import styles from "./Employee.module.scss"

interface Props extends IEmployee {
  className?: string
  highlightTxt?: string
  size?: "sm"
  onClick?: (val: string) => void
}

const colors = ["#FA8B81", "#FED45A", "#2DBE64"]

function capitalizeFirstLetter(string: string) {
  const arr = string.split(" ")
  const first = arr[0].charAt(0).toUpperCase() + arr[0].slice(1)
  let second = ""
  if (arr.length > 1) second = " " + arr[1].charAt(0).toUpperCase() + arr[1].slice(1)
  return first + second
}

const Employee: FC<Props> = ({
  className,
  size,
  onClick,
  highlightTxt,
  id,
  name,
  avatar_id,
  position,
  email,
  phoneNumber,
}) => {
  const [createChat, { isLoading: isLoadingCreateChat }] = useCreateChatMutation()

  const [isModalMesOpen, setModalMessOpen] = useState<boolean>(false)
  const [chatId, setChatId] = useState<string>("")

  const img = useMemo(() => {
    if (avatar_id) {
      return (
        <Avatar className={styles.avatar} img={getApiUrl() + "/web/user/avatar/" + avatar_id} name={name} size={"lg"} />
      )
    }
    if (!name) return null
    const userName = name.split(" ")
    const avatarNameFirst = userName[0].split("")[0]
    const avatarNameSecond = userName.length > 1 ? userName[1].split("")[0] : ""
    const color = colors[Math.floor(Math.random() * colors.length)]
    return (
      <div className={clsx(styles.imgDummy)} style={{ backgroundColor: color }}>
        {avatarNameFirst}
        {avatarNameSecond}
      </div>
    )
  }, [avatar_id, name])

  const userName = useMemo(() => {
    if (!highlightTxt) return name
    const currentName = capitalizeFirstLetter(name)
    const currentSearch = capitalizeFirstLetter(highlightTxt)
    const index = currentName.indexOf(currentSearch)
    let searchedName = currentName
    if (index >= 0) {
      searchedName =
        searchedName.substring(0, index) +
        `<span class=${styles.highlight}>` +
        searchedName.substring(index, index + currentSearch.length) +
        "</span>" +
        searchedName.substring(index + currentSearch.length)
      return searchedName
    } else {
      return name
    }
  }, [name, highlightTxt])

  const handleOpenChat = () => {
    createChat({ type: "users", id: id })
      .unwrap()
      .then((res) => {
        setChatId(res.id)
        setModalMessOpen(true)
      })
  }

  return (
    <>
      <div
        className={clsx(styles.item, size && styles["item--" + size], className)}
        onClick={() => {
          if (onClick) onClick(id)
        }}
      >
        <div className={styles.content}>
          <p className={styles.name} dangerouslySetInnerHTML={{ __html: userName }} />
          {size !== "sm" && position && <p className={styles.position}>{position}</p>}
          {size !== "sm" && email && (
            <a href={"mailto:" + email} className={styles.link}>
              {email}
            </a>
          )}
          <br />
          {size !== "sm" &&
            phoneNumber?.length > 0 &&
            phoneNumber?.map((phone, index) => (
              <Fragment key={index}>
                <a href={"tel:" + phoneNumber} className={styles.link}>
                  {phone}
                </a>
                {phoneNumber.length - 1 !== index && ", "}
              </Fragment>
            ))}

          {size !== "sm" && (
            <button
              disabled={isLoadingCreateChat}
              type={"button"}
              className={clsx(styles.btn, isLoadingCreateChat && styles["btn--is-loading"])}
              onClick={handleOpenChat}
            >
              {isLoadingCreateChat && <BtnLoader />}
              <ChatIcon />
              Написать
            </button>
          )}
        </div>
        {img}
      </div>

      {isModalMesOpen && <MessagesModal open={isModalMesOpen} setOpen={setModalMessOpen} firstChatId={chatId} />}
    </>
  )
}

export default Employee
