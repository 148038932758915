import Search from "../Search/Search"
import { useCallback, useEffect, useState } from "react"
import _debounce from "lodash/debounce"
import ArticlesFilter from "./ArticlesFilter/ArticlesFilter"
import ArticlesFavorites from "./ArticlesFavorites/ArticlesFavorites"
import ArticlesPrevBlock from "./ArticlesPrevBlock/ArticlesPrevBlock"
import ArticlesSearch from "./ArticlesSearch/ArticlesSearch"
import {
  useGetArticlesTagsQuery,
  useGetFavoriteArticlesQuery,
  useGetPopularArticlesQuery,
  useLazyGetArticlesQuery,
} from "../../redux/api/articles"
import clsx from "clsx"
import { useDispatch } from "react-redux"
import { setFavoriteArticles } from "../../redux/slice/articles"
import { IArticle } from "../../types/content"
import useWindowSize from "../../hooks/useWindowSize"
import ArticlesAside from "./ArticlesAside/ArticlesAside"
import TitleBack from "../TitleBack/TitleBack"
import styles from "./Articles.module.scss"

const Articles = () => {
  const dispatch = useDispatch()
  const { isDesktop } = useWindowSize()

  const { data: tags, isLoading: isTagsLoading } = useGetArticlesTagsQuery()
  const { data: popularArticles, isLoading: isLoadingPopular } = useGetPopularArticlesQuery()
  const { data: favoriteArticles, isLoading: isFavoriteLoading } = useGetFavoriteArticlesQuery()
  const [getSearchArticles] = useLazyGetArticlesQuery()

  const [searchVal, setSearchVal] = useState<string>("")
  const [searchArticles, setSearchArticles] = useState<IArticle[]>([])
  const [isSearchLoading, setSearchLoading] = useState<boolean>(false)
  const [filter, setFilter] = useState<{ search: string; tags: string[] }>({
    search: "",
    tags: [],
  })

  const debounceFn = useCallback(
    _debounce((str: string) => {
      setFilter((prev) => {
        return { ...prev, search: str }
      })
    }, 500),
    [],
  )

  useEffect(() => {
    debounceFn(searchVal)
  }, [searchVal])

  useEffect(() => {
    if (!filter.tags?.length && !filter.search) return
    setSearchLoading(true)
    getSearchArticles({ search: filter.search, tags: filter.tags }).then(({ data }) => {
      setSearchArticles(data || [])
      setSearchLoading(false)
    })
  }, [filter])

  useEffect(() => {
    dispatch(setFavoriteArticles(favoriteArticles || []))
  }, [favoriteArticles])

  return (
    <div>
      <header className={styles.header}>
        <TitleBack title={"База знаний"} className={styles.titleBack} />
        <Search searchVal={searchVal} setSearchVal={setSearchVal} className={styles.search} />
      </header>

      {isTagsLoading ? (
        <div className={clsx(styles.tagsLoader)}>
          {[...Array(4)].map((_item, index) => (
            <div key={index} className={clsx("skeletonBlock", styles.tagsLoaderBtn)} />
          ))}
        </div>
      ) : tags?.aTags ? (
        <ArticlesFilter list={tags.aTags} filter={filter} setFilter={setFilter} className={styles.filter} />
      ) : null}

      {!isDesktop && <ArticlesAside />}

      {filter.search || filter.tags.length > 0 ? (
        <>
          {isSearchLoading ? (
            <div className={clsx("skeletonBlock", styles.searchLoading)} />
          ) : (
            <ArticlesSearch
              isOnlyTag={!filter.search && filter.tags.length > 0}
              clearSearch={() => {
                setSearchVal("")
                setFilter({
                  search: "",
                  tags: [],
                })
              }}
              list={searchArticles}
            />
          )}
        </>
      ) : (
        <>
          {isFavoriteLoading ? <div className={clsx("skeletonBlock", styles.favLoading)} /> : <ArticlesFavorites />}

          <div className={styles.bottomGrid}>
            {isLoadingPopular ? (
              <>
                <div className={clsx("skeletonBlock", styles.prevLoading)} />
                <div className={clsx("skeletonBlock", styles.prevLoading)} />
              </>
            ) : (
              <>
                {popularArticles?.new && <ArticlesPrevBlock title={"Новые для вас"} list={popularArticles.new} />}
                {popularArticles?.popular && (
                  <ArticlesPrevBlock title={"Популярные статьи"} list={popularArticles.popular} />
                )}
              </>
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default Articles
