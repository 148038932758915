import { Dispatch, FC, SetStateAction, useState, ChangeEvent } from "react"
import ModalPortal from "../../Assets/ModalPortal/ModalPortal"
import { useTranslation } from "react-i18next"
import { removeActiveOrdersCount } from "../../../redux/slice/auth"
import { usePostCancelOrderMutation } from "../../../redux/api/content"
import { useDispatch } from "react-redux"
import TextArea from "../../Assets/TextArea/TextArea"
import Button from "../../Assets/Button/Button"
import styles from "./OrderCallbackModal.module.scss"

interface Props {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  orderId: string
  delFromList?: (orderId: string) => void
  callbackClose: () => void
  layout?: "history" | "active"
}
const OrderCallbackModal: FC<Props> = ({ open, setOpen, orderId, delFromList, callbackClose, layout }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation("translation", { keyPrefix: `interface` })

  const [cancelOrder] = usePostCancelOrderMutation()

  const [declineOrderText, setDeclineOrderText] = useState("")
  const [isRequest, setIsRequest] = useState(false)

  const declineOrder = () => {
    if (layout === "history") {
      // TODO: запроса с бека еще нет
      return false
    }
    if (orderId) {
      setIsRequest(true)
      cancelOrder({ id: orderId, reason: declineOrderText })
        .unwrap()
        .then(() => {
          setIsRequest(false)
          dispatch(removeActiveOrdersCount())
          // Удаления из списка текущих заявок
          if (delFromList) delFromList(orderId ?? "")
          setOpen(false)
          callbackClose()
        })
        .catch((e) => {
          console.warn(e)
          setIsRequest(false)
        })
    }
  }

  return (
    <ModalPortal
      isOpen={open}
      setIsOpen={setOpen}
      size={"sm"}
      header={<h2 className={"modal__title"}>{layout === "active" ? t("confirmCancellation") : "Вернуть в работу"}</h2>}
    >
      <p className={styles.txt}>{layout === "active" ? t("getReasonCancelOrder") : "Укажите причину"}</p>
      <TextArea
        placeholder={t("enterComment")}
        value={declineOrderText}
        onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setDeclineOrderText(e.target.value)}
      />
      <Button txt={t("confirmBtn")} mode={"gray"} onClick={declineOrder} isLoading={isRequest} className={styles.btn} />
    </ModalPortal>
  )
}

export default OrderCallbackModal
