import { FC, useEffect, useRef, useState } from "react"
import { selectAuth } from "../../redux/slice/auth"
import { useAppSelector } from "../../hooks"
import { useGetCompanyLogoQuery } from "../../redux/api/auth"
import LocalizedLink from "../../hoc/LocalizedLink"
import styles from "./Modal.module.scss"
import clsx from "clsx"
import { useScrollBlock } from "../../hooks/useScrollBlock"

interface Props {
  open: boolean
  setOpen: any
  children?: any
  isOrder?: boolean
  refContent?: any
  addClass?: string
  contentClass?: string
  isLogo?: boolean
}

const Modal: FC<Props> = ({
  children,
  open,
  addClass,
  setOpen,
  isOrder = false,
  refContent = null,
  contentClass,
  isLogo,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [appendClass, setAppendClass] = useState(false)

  const modalRef = useRef<HTMLDivElement>(null)

  const user = useAppSelector(selectAuth)
  const { data: logoCompany } = useGetCompanyLogoQuery()

  useEffect(() => {
    if (open) setAppendClass(true)
    else setAppendClass(false)

    const body = document.getElementsByTagName("body")[0]
    const modalElement = modalRef?.current

    if (open) {
      body.classList.add("noscroll")
      setIsOpen(true)
    } else if (body.classList.contains("noscroll") && !open) {
      if (!modalElement) return
      body.classList.remove("noscroll")
      setIsOpen(false)
    }
  }, [open, modalRef])

  return isOpen ? (
    <div
      ref={modalRef}
      className={clsx(
        "modal",
        styles.modal,
        isOrder && styles.modal_order,
        addClass && styles[addClass] + " modal-" + addClass,
      )}
      onClick={(e: any) => {
        if (e.target.closest(`.modal__content`)) return
        if (!user.token) return
        setIsOpen(false)
        setOpen(false)
        if (document.querySelectorAll(".modal")?.length < 2) {
          document.body.classList.remove("noscroll")
        }
      }}
    >
      <div className={`modal__wp ${styles.modal__wp} ${addClass ? styles[`${addClass}__wp`] : ""}`}>
        <div className={`modal__wrapper ${styles.modal__wrapper}`}>
          {isLogo && logoCompany && logoCompany?.data?.length !== 0 ? (
            <div className={styles.modal__logo}>
              <LocalizedLink to={"/"}>
                <div className={styles.modal__logo__container} dangerouslySetInnerHTML={{ __html: logoCompany }} />
              </LocalizedLink>
            </div>
          ) : null}
          {isLogo && <p className={styles.modal__logoTxt}>powered by Staq</p>}
          <div className={`modal__body ${styles.modal__body} ${addClass ? styles[`${addClass}__body`] : ""}`}>
            <div
              className={`modal__content ${styles.modal__content} ${appendClass ? styles.modal__content_opened : ""} ${
                contentClass ?? ""
              }`}
              ref={refContent}
            >
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null
}

export default Modal
