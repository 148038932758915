// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SliderNav_nav__9xFez {
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  flex-shrink: 0;
  pointer-events: none;
}

.SliderNav_btn__SkX9r {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  background-color: var(--white);
  box-shadow: -8px 0px 24px 0px rgba(0, 0, 0, 0.0901960784);
  border-radius: 100%;
  pointer-events: auto;
  transition: var(--transition);
  transition-property: background-color;
}
@media (hover: hover) {
  .SliderNav_btn__SkX9r:hover {
    background-color: #EEE;
  }
}
.SliderNav_btn--prev__cyLhK svg {
  transform: rotate(180deg);
}
.SliderNav_btn__SkX9r[disabled] {
  opacity: 0;
  pointer-events: none;
}
.SliderNav_btn--white__dc6uw {
  box-shadow: none;
  border-radius: 4px;
}
@media (hover: hover) {
  .SliderNav_btn--white__dc6uw:hover {
    background-color: #EEE;
  }
}
.SliderNav_btn--white__dc6uw[disabled] {
  opacity: 0.4;
  pointer-events: none;
}`, "",{"version":3,"sources":["webpack://./src/components/Assets/SliderNav/SliderNav.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,UAAA;EACA,cAAA;EACA,oBAAA;AACF;;AAEA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;EACA,8BAAA;EACA,yDAAA;EACA,mBAAA;EACA,oBAAA;EACA,6BAAA;EACA,qCAAA;AACF;AACE;EACE;IACE,sBAAA;EACJ;AACF;AAGI;EACE,yBAAA;AADN;AAKE;EACE,UAAA;EACA,oBAAA;AAHJ;AAME;EACE,gBAAA;EACA,kBAAA;AAJJ;AAMI;EACE;IACE,sBAAA;EAJN;AACF;AAOI;EACE,YAAA;EACA,oBAAA;AALN","sourcesContent":[".nav {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  align-items: center;\r\n  z-index: 1;\r\n  flex-shrink: 0;\r\n  pointer-events: none;\r\n}\r\n\r\n.btn {\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  width: 24px;\r\n  height: 24px;\r\n  background-color: var(--white);\r\n  box-shadow: -8px 0px 24px 0px #00000017;\r\n  border-radius: 100%;\r\n  pointer-events: auto;\r\n  transition: var(--transition);\r\n  transition-property: background-color;\r\n\r\n  @media (hover: hover) {\r\n    &:hover {\r\n      background-color: #EEE;\r\n    }\r\n  }\r\n\r\n  &--prev {\r\n    svg {\r\n      transform: rotate(180deg);\r\n    }\r\n  }\r\n\r\n  &[disabled] {\r\n    opacity: 0;\r\n    pointer-events: none;\r\n  }\r\n\r\n  &--white {\r\n    box-shadow: none;\r\n    border-radius: 4px;\r\n\r\n    @media (hover: hover) {\r\n      &:hover {\r\n        background-color: #EEE;\r\n      }\r\n    }\r\n\r\n    &[disabled] {\r\n      opacity: 0.4;\r\n      pointer-events: none;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nav": `SliderNav_nav__9xFez`,
	"btn": `SliderNav_btn__SkX9r`,
	"btn--prev": `SliderNav_btn--prev__cyLhK`,
	"btn--white": `SliderNav_btn--white__dc6uw`
};
export default ___CSS_LOADER_EXPORT___;
