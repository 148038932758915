import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../store"

export interface mobileMoreModalState {
  openModal: boolean
}

const initialState: mobileMoreModalState = {
  openModal: false,
}

export const MobileMoreModalSlice = createSlice({
  name: "mobileMoreModal",
  initialState,
  reducers: {
    setMobileMoreModalOpen: (state: mobileMoreModalState, action: PayloadAction<boolean>) => {
      state.openModal = action.payload
    },
  },
})

export const { setMobileMoreModalOpen } = MobileMoreModalSlice.actions

export const getMobileMoreModalOpen = (state: RootState) => state[MobileMoreModalSlice.name].openModal
