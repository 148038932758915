import { FC, ReactNode } from "react"
import useSetSettings from "../../hooks/useSetSettings"
import clsx from "clsx"
import Footer from "../Footer"
import Header from "../Header"
import useWindowSize from "../../hooks/useWindowSize"
import OrdersLine from "../OrdersLine/OrdersLine"
import { useAppSelector } from "../../hooks"
import { selectAuth } from "../../redux/slice/auth"
import OrdersLineLoader from "../OrdersLine/OrdersLineLoader"
import MobileMenu from "../MobileMenu/MobileMenu"
import MobileMoreModal from "../Modals/MobileMoreModal/MobileMoreModal"
import { getMobileMoreModalOpen } from "../../redux/slice/isMoreModal"
import LanguageModal from "../Modals/LanguageModal/LanguageModal"

interface Props {
  children: ReactNode
  mainClass?: string
  asideClass?: string
  isHeaderMobHidden?: boolean
  isAsideHidden?: boolean
  aside?: ReactNode
}

const Layout: FC<Props> = ({ children, mainClass, asideClass, isHeaderMobHidden, isAsideHidden, aside }) => {
  useSetSettings()

  const user = useAppSelector(selectAuth)
  const mobileMoreModalIsOpen = useAppSelector(getMobileMoreModalOpen)
  const { isDesktop } = useWindowSize()

  return (
    <div className="wrapper">
      <Header isMobHidden={isHeaderMobHidden} />
      <main className={clsx(mainClass)}>
        {isDesktop && !isAsideHidden && (
          <aside className={clsx("main-aside", asideClass)}>
            <div className={"main-aside__inner"}>
              {aside ? aside : user.token ? <OrdersLine /> : <OrdersLineLoader />}
            </div>
          </aside>
        )}
        <div className={clsx("main-content", isAsideHidden && "main-content--full")}>{children}</div>
      </main>
      <Footer />
      {!isDesktop && <MobileMenu />}
      {user.token && !isDesktop && mobileMoreModalIsOpen && <MobileMoreModal />}
      <LanguageModal />
    </div>
  )
}

export default Layout
