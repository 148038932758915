// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OrderDescripton_description__MiQTC {
  padding: 12px;
}
.OrderDescripton_description--is-loading__cS6S8 {
  height: 200px;
  margin-top: 12px;
  margin-left: 12px;
  margin-right: 12px;
}

.OrderDescripton_list__irCaZ {
  list-style-type: none;
  padding: 0;
}

.OrderDescripton_row__Un5De {
  margin-bottom: 8px;
}
.OrderDescripton_row__Un5De:last-child {
  margin-bottom: 0;
}

.OrderDescripton_rowTitle__v9Rvq {
  font-size: 10px;
  line-height: 12px;
  font-weight: 500;
  color: #54575C;
}

.OrderDescripton_rowTxt__ZxLuB {
  font-size: var(--fz-sm);
  line-height: var(--lh-sm);
}

.OrderDescripton_assignee__P6A9O {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}
.OrderDescripton_assignee__avatar__4I6qA {
  flex-shrink: 0;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
}
.OrderDescripton_assignee__name__XVkXR {
  font-size: var(--fz-sm);
  line-height: var(--lh-sm);
  color: #54575C;
}`, "",{"version":3,"sources":["webpack://./src/components/OrderDescription/OrderDescripton.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AACE;EACE,aAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;AACJ;;AAGA;EACE,qBAAA;EACA,UAAA;AAAF;;AAGA;EACE,kBAAA;AAAF;AAEE;EACE,gBAAA;AAAJ;;AAIA;EACE,eAAA;EACA,iBAAA;EACA,gBAAA;EACA,cAAA;AADF;;AAIA;EACE,uBAAA;EACA,yBAAA;AADF;;AAIA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,mBAAA;AADF;AAGE;EACE,cAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,iBAAA;EACA,uBAAA;AADJ;AAIE;EACE,uBAAA;EACA,yBAAA;EACA,cAAA;AAFJ","sourcesContent":[".description {\r\n  padding: 12px;\r\n\r\n  &--is-loading {\r\n    height: 200px;\r\n    margin-top: 12px;\r\n    margin-left: 12px;\r\n    margin-right: 12px;\r\n  }\r\n}\r\n\r\n.list {\r\n  list-style-type: none;\r\n  padding: 0;\r\n}\r\n\r\n.row {\r\n  margin-bottom: 8px;\r\n\r\n  &:last-child {\r\n    margin-bottom: 0;\r\n  }\r\n}\r\n\r\n.rowTitle {\r\n  font-size: 10px;\r\n  line-height: 12px;\r\n  font-weight: 500;\r\n  color: #54575C;\r\n}\r\n\r\n.rowTxt {\r\n  font-size: var(--fz-sm);\r\n  line-height: var(--lh-sm);\r\n}\r\n\r\n.assignee {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  align-items: center;\r\n  margin-bottom: 12px;\r\n\r\n  &__avatar {\r\n    flex-shrink: 0;\r\n    width: 38px;\r\n    height: 38px;\r\n    border-radius: 50%;\r\n    object-fit: cover;\r\n    object-position: center;\r\n  }\r\n\r\n  &__name {\r\n    font-size: var(--fz-sm);\r\n    line-height: var(--lh-sm);\r\n    color: #54575C;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"description": `OrderDescripton_description__MiQTC`,
	"description--is-loading": `OrderDescripton_description--is-loading__cS6S8`,
	"list": `OrderDescripton_list__irCaZ`,
	"row": `OrderDescripton_row__Un5De`,
	"rowTitle": `OrderDescripton_rowTitle__v9Rvq`,
	"rowTxt": `OrderDescripton_rowTxt__ZxLuB`,
	"assignee": `OrderDescripton_assignee__P6A9O`,
	"assignee__avatar": `OrderDescripton_assignee__avatar__4I6qA`,
	"assignee__name": `OrderDescripton_assignee__name__XVkXR`
};
export default ___CSS_LOADER_EXPORT___;
