import { FC, useCallback, useEffect, useMemo, useState } from "react"
import TitleBack from "../TitleBack/TitleBack"
import OrdersList from "../OrdersList/OrdersList"
import HistoryOrdModal from "../Modals/HistoryOrdModal/HistoryOrdModal"
import ActOrdModal from "../Modals/ActOrdModal/ActOrdModal"
import { useTranslation } from "react-i18next"
import { useLazyPostServiceOrdersQuery } from "../../redux/api/content"
import { useNavigate } from "react-router-dom"
import RatingModal from "../Modals/RatingModal/RatingModal"
import { useDispatch } from "react-redux"
import { clearMessageCountId, setNeedClearNewMessageCount, updateOrdersList } from "../../redux/slice/ordersList"
import { useAppSelector } from "../../hooks"
import useWindowSize from "../../hooks/useWindowSize"
import Search from "../Search/Search"
import _debounce from "lodash/debounce"
import { IServiceOrderShort } from "../../types/content"
import OrderInfoModal from "../Modals/OrderInfoModal/OrderInfoModal"

interface Props {
  layout?: "history" | "active"
}

const OrdersLayout: FC<Props> = ({ layout }) => {
  // Модалка истории подробнее
  const [historyModal, setHistoryModal] = useState(false)
  const [actOrderModal, setActOrderModal] = useState(false)
  const [orderInfoModal, setOrderInfoModal] = useState<boolean>(false)
  const [isRatingModal, setIsRatingModal] = useState<boolean>(false)
  const [getActiveOrders, { data: requestsData, isLoading }] = useLazyPostServiceOrdersQuery()
  const [currentPage, setCurrentPage] = useState(0)
  const [fetching, setFetching] = useState(false)
  const [isHaveMore, setIsHaveMore] = useState<boolean | undefined>(undefined)
  const [orders, setOrders] = useState<IServiceOrderShort[]>([])
  const [openedOrder, setOpenedOrder] = useState<string | undefined>(undefined)
  const [isFirstRender, setIsFirstRender] = useState(true)
  const [searchVal, setSearchVal] = useState<string>("")
  const [chatID, setChatID] = useState<string>("")

  const clearMessageCountIdStr = useAppSelector(clearMessageCountId)
  const { isDesktop } = useWindowSize()

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { t } = useTranslation("translation", { keyPrefix: `interface` })

  const orderClickFcn = (order: IServiceOrderShort) => {
    if (isDesktop) {
      setOpenedOrder(order.id)
      setChatID(order.chat_dialog_id)
      setOrderInfoModal(true)
      return
    }
    if (layout === "active") {
      setActOrderModal(true)
    } else {
      setHistoryModal(true)
    }
    setOpenedOrder(order.id)
  }

  const ratingOpenFn = (id: string) => {
    setIsRatingModal(true)
    setOpenedOrder(id)
  }

  useEffect(() => {
    if (fetching && !isLoading) {
      if (isHaveMore !== undefined && !isHaveMore) {
        return
      } else {
        getActiveOrders({ limit: 7, offset: currentPage * 7, isActive: layout === "active" })
          .unwrap()
          .then((res) => {
            setOrders((ords) => [...ords, ...res.data.aItems])
            dispatch(updateOrdersList({ list: res.data.aItems }))
            setIsHaveMore(!res.data.bIsEnd)
            setFetching(false)
            setCurrentPage((prev) => prev + 1)
          })
      }
    }
  }, [fetching])

  useEffect(() => {
    getActiveOrders({ limit: 7, offset: currentPage * 7, isActive: layout === "active" })
      .unwrap()
      .then((res) => {
        window.scrollTo({
          top: 0,
          behavior: "auto",
        })
        setOrders([...res.data.aItems])
        dispatch(updateOrdersList({ list: res.data.aItems }))
        setIsHaveMore(!res.data.bIsEnd)
        setIsFirstRender(false)
        setCurrentPage((prev) => prev + 1)
        checkHeightWindowForFetching()
      })
      .catch((err) => {
        if (err.status === 401) navigate("/")
        console.warn(err)
      })
    document.addEventListener("scroll", scrollHandler)
    return () => {
      document.removeEventListener("scroll", scrollHandler)
    }
  }, [])

  // Если появляется айди (записываемое в модалке чата), то для него обнуляем счетчик непрочитанных сообщений
  useEffect(() => {
    if (clearMessageCountIdStr) {
      setOrders((ords) => [...ords.map((el) => (el.id === clearMessageCountIdStr ? { ...el, message_counts: 0 } : el))])
      dispatch(setNeedClearNewMessageCount(""))
    }
  }, [clearMessageCountIdStr])

  const checkHeightWindowForFetching = () => {
    const windowHeight = window.innerHeight
    const mainHeight = document.querySelector("main")?.offsetHeight

    if (mainHeight) {
      if (windowHeight >= mainHeight && !isLoading) {
        setFetching(true)
      }
    }
  }

  const scrollHandler = (e: any) => {
    if (e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) < 80) {
      if (!isLoading) {
        setFetching(true)
      }
    }
  }

  // ф-я удаления из списка текущих заявок при отмене
  const delOrderFromList = (orderId: string) => {
    setOrders((ords) => [...ords.filter((el) => el.id !== orderId)])
  }

  const [ordersSearch, setOrdersSearch] = useState<string>("")
  const debounceFn = useCallback(
    _debounce((str: string) => {
      setOrdersSearch(str)
    }, 500),
    [],
  )
  useEffect(() => {
    debounceFn(searchVal)
  }, [searchVal])

  const ordersList = useMemo(() => {
    if (!ordersSearch) return orders
    return orders.filter(({ name }) => name?.toLowerCase().includes(ordersSearch.toLowerCase()))
  }, [orders, ordersSearch])

  return (
    <>
      <TitleBack title={layout === "active" ? t("actRequests") : t("historyRequests")} />

      <Search searchVal={searchVal} setSearchVal={setSearchVal} />

      <OrdersList
        layout={layout}
        orders={isFirstRender && requestsData ? [...requestsData.data.aItems] : ordersList}
        clickFcn={orderClickFcn}
        ratingFn={ratingOpenFn}
        isLoading={isLoading}
        isFirstRender={isFirstRender}
        isLoadingAppend={fetching && isHaveMore}
      />
      {openedOrder && historyModal ? (
        <HistoryOrdModal openedOrder={openedOrder} open={historyModal} setOpen={setHistoryModal} />
      ) : null}
      {actOrderModal ? (
        <ActOrdModal
          openedOrder={openedOrder}
          open={actOrderModal}
          setOpen={setActOrderModal}
          delFromList={delOrderFromList}
        />
      ) : null}
      {orderInfoModal && openedOrder && (
        <OrderInfoModal
          id={openedOrder}
          chatID={chatID}
          open={orderInfoModal}
          setOpen={setOrderInfoModal}
          delFromList={delOrderFromList}
          layout={layout}
        />
      )}
      {openedOrder && isRatingModal && (
        <RatingModal orderId={openedOrder} open={isRatingModal} setOpen={setIsRatingModal} />
      )}
    </>
  )
}

export default OrdersLayout
