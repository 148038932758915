import React, { useState, useEffect, useRef, Dispatch, SetStateAction } from "react"
import styles from "./ActOrdModal.module.scss"
import Modal from "../../Modal/Modal"
import { usePostCancelOrderMutation, useLazyGetServiceOrderQuery } from "../../../redux/api/content"
import moment from "moment"
import { useTranslation } from "react-i18next"
import SuccessModal from "../SuccessModal/SuccessModal"
import { useDispatch } from "react-redux"
import { removeActiveOrdersCount } from "../../../redux/slice/auth"
import { useAppSelector } from "../../../hooks"
import ReactTextareaAutosize from "react-textarea-autosize"
import BtnLoader from "../../Assets/BtnLoader/BtnLoader"
import HistoryOrdModalSkeleton from "../HistoryOrdModal/HistoryOrdModalSkeleton"
import InformationRow from "../HistoryOrdModal/assets/InformationRow"
import FileRow from "../HistoryOrdModal/assets/FileRow"
import ChatModal from "../ChatModal/ChatModal"
import { getApiUrl } from "../../../utils/getApiUrl"
import { selectAllMessage, setAllMessage } from "../../../redux/slice/main"
import { setOpenOrder } from "../../../redux/slice/ordersList"
import { useLazyGetChatMessagesQuery } from "../../../redux/api/chat"
import TitleBack from "../../TitleBack/TitleBack"

interface Props {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  openedOrder?: string
  delFromList?: (orderId: string) => void
}

const ActOrdModal: React.FC<Props> = ({ open, setOpen, openedOrder, delFromList = () => null }) => {
  const dispatch = useDispatch()
  const [declineOrderText, setDeclineOrderText] = useState("")
  const [cancelStep, setCancelStep] = useState(1)
  const [getServiceOrder, { data: orderInfo }] = useLazyGetServiceOrderQuery()
  const [isRequest, setIsRequest] = useState(false)
  // Открыть модалку чата
  const [openChat, setOpenChat] = useState(false)
  const isFirstLoadMess = useRef<any>(false)
  // const activeOrders = useAppSelector(selectActiveOrders)
  const [cancelOrder] = usePostCancelOrderMutation()
  // Список сообщений в чате
  const [getChatList] = useLazyGetChatMessagesQuery()
  // Сообщения чата
  const allMessage = useAppSelector(selectAllMessage)
  const { t } = useTranslation("translation", { keyPrefix: `interface` })

  const [loading, setLoading] = useState(true)

  // Количество непрочитанных сообщений
  const allMessageNoRead = allMessage?.filter((ms: any) => !ms?.is_readed)?.length

  useEffect(() => {
    if (openedOrder) {
      setLoading(true)
      getServiceOrder(openedOrder)
        .then((resp: any) => {
          setLoading(false)
          dispatch(setOpenOrder(resp.data.data))
          getChatList(resp.data.data.order_id)
            .then((res: any) => {
              isFirstLoadMess.current = true
              dispatch(setAllMessage(res.data.aMessages))
            })
            .catch((er) => console.log(er.message))
        })
        .catch((err) => console.log(err))
    }
  }, [openedOrder])

  const displayContent = (type: string, option: { [key: string]: any }) => {
    switch (type) {
      case "text":
        return <>{option.value}</>
      case "boolean":
        return <>{option.value === true ? t("yes") : t("no")}</>
      case "checkbox":
        return <>{option.value === true ? t("yes") : t("no")}</>
      case "number":
        return <>{option.value}</>
      case "datetime":
        if (typeof option.value === "number") {
          return <>{moment(Number(option.value)).format("DD MMMM YYYY HH:mm")}</>
        } else {
          return <>{moment(option.value).format("DD MMMM YYYY")}</>
        }

      case "list":
        return (
          <>
            {option.items.map((item: { id: string; name: string }, i: number) => {
              return `${item.name}${i !== option.items.length - 1 ? ", " : ""}`
            })}
          </>
        )
      case "photo":
        return (
          <div className={styles.container_preview_list}>
            {option.images.map((image: string, i: number) => (
              <img key={i} src={getApiUrl() + "/public/photo/preview/" + image} alt="" />
            ))}
          </div>
        )
      default:
        return <></>
    }
  }

  const declineOrder = () => {
    if (orderInfo?.id) {
      // Прелодаер на кнопке
      setIsRequest(true)
      cancelOrder({ id: orderInfo.id, reason: declineOrderText })
        .unwrap()
        .then((res) => {
          setIsRequest(false)
          dispatch(removeActiveOrdersCount())
          setCancelStep(10)
          // Удаления из списка текущих заявок
          delFromList(orderInfo.id ?? "")
        })
        .catch(() => setIsRequest(false))
    }
  }

  const swipedModal: any = useRef(null)

  const newOrderInfo = orderInfo
  return (
    <>
      <Modal open={open} setOpen={setOpen} addClass={"act-order"} refContent={swipedModal}>
        <div className={styles.historyOrdModal}>
          {loading ? (
            <HistoryOrdModalSkeleton />
          ) : (
            <>
              {cancelStep === 1 && (
                <>
                  <div className={styles.historyOrdModal__top}>
                    <TitleBack
                      title={`${newOrderInfo?.name}
                      ${newOrderInfo?.count && newOrderInfo.count > 1 ? <span>x{newOrderInfo.count}</span> : ""}`}
                      onClick={() => setOpen(false)}
                      noLinkBack
                    />
                  </div>
                  {newOrderInfo?.TechnicalObject ? (
                    <div className={styles.historyOrdModal__line}>
                      <div className={styles.historyOrdModal__topText}>
                        <p>Объект</p>
                        <h5>{newOrderInfo?.TechnicalObject} </h5>
                      </div>
                    </div>
                  ) : null}

                  <>
                    {newOrderInfo?.options.map((option: { [key: string]: any }) =>
                      option?.type === "information" ? (
                        <InformationRow el={option} key={option.id} />
                      ) : option?.type === "attachment" ? (
                        <div className={styles.historyOrdModal__line} key={option.id}>
                          <div className={styles.historyOrdModal__topText}>
                            <div className={`${styles.orderModal__files}`}>
                              <p>{t("attachments")}</p>
                              {option?.files.map((ell: any, kkk: number) => <FileRow el={ell} key={kkk} />)}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div key={option.id} className={styles.historyOrdModal__line}>
                          <div className={styles.historyOrdModal__topText}>
                            <p>{option.name}</p>
                            <h5>{displayContent(option.type, option)}</h5>
                          </div>
                        </div>
                      ),
                    )}
                  </>

                  <div className={styles.historyOrdModal__line}>
                    <div className={styles.historyOrdModal__topText}>
                      <p>{t("date")}</p>
                      <h5>{moment(newOrderInfo?.created_at).format("DD MMMM YYYY")}</h5>
                    </div>
                  </div>
                  <div className={`${styles.historyOrdModal__line}`}>
                    <div className={styles.historyOrdModal__topText}>
                      <p>{t("orderTime")}</p>
                      <h5>{moment(newOrderInfo?.created_at).format("kk:mm")}</h5>123123123
                    </div>
                  </div>
                  <div className={`${styles.historyOrdModal__line}`}>
                    <div className={styles.historyOrdModal__topText}>
                      <p>{t("orderNum")}</p>
                      <h5>{newOrderInfo?.number}</h5>
                    </div>
                  </div>
                  <div className={`${styles.historyOrdModal__bottom}`}>
                    <button type="button" onClick={() => setCancelStep(2)} className={`btn btn_orange`}>
                      {t("cancel")}
                    </button>
                    <div className={`${styles.historyOrdModal__bottomTwoBtns}`}>
                      <button
                        type="button"
                        onClick={() => {
                          setOpen(false)
                          document.body.classList.remove("noscroll")
                        }}
                        className={`btn btn_grey`}
                      >
                        {t("back")}
                      </button>
                      <button type="button" onClick={() => setOpenChat(true)} className={`btn btn_grey`}>
                        <img src="/img/chat.svg" alt="" />
                        {t("messages")}
                        {!!allMessageNoRead && (
                          <span className={`${styles.ordersLine__ordersNoReview}`}>{allMessageNoRead}</span>
                        )}
                      </button>
                    </div>
                  </div>
                </>
              )}
              {cancelStep === 2 && (
                <div className="confirm-cancellation">
                  <div className={`modal-head`}>
                    <div className={`modal-title`}>{t("confirmCancellation")}</div>
                  </div>
                  <div className={`modal-input modal-input_cancel`}>
                    <div className={`input-label`}>{t("getReasonCancelOrder")}</div>
                    <ReactTextareaAutosize
                      minRows={1}
                      maxRows={3}
                      placeholder={t("enterComment")}
                      onChange={(e) => setDeclineOrderText(e.target.value)}
                      value={declineOrderText}
                    />
                  </div>
                  <div className={`modal-bottom`}>
                    <button type="button" onClick={() => setCancelStep(1)} className={`btn btn_grey btn_sm`}>
                      {t("back")}
                    </button>

                    <button
                      type="button"
                      onClick={() => {
                        declineOrder()
                      }}
                      className={`btn btn_orange`}
                    >
                      {t("confirmBtn")}
                      {isRequest && <BtnLoader />}
                    </button>
                  </div>
                </div>
              )}
              {cancelStep === 10 && (
                <SuccessModal
                  modalTitle={`${t("cancelOrderApplication")} ${newOrderInfo?.number} ${t("cancelOrderCanceled")}`}
                  modalText={t("cancelOrderComment")}
                  setOpen={() => {
                    setOpen(false)
                    document.body.classList.remove("noscroll")
                  }}
                  open={true}
                />
              )}
            </>
          )}
        </div>
      </Modal>
      {openChat && newOrderInfo && (
        <ChatModal
          chatID={newOrderInfo.chat_dialog_id}
          openChat={openChat}
          setOpenChat={setOpenChat}
          orderInfo={newOrderInfo}
          isFirstLoadMess={isFirstLoadMess.current}
        />
      )}
    </>
  )
}

export default ActOrdModal
