import { useMemo } from "react"
import moment from "moment/moment"
import Review from "../Review/Review"
import styles from "./Reviews.module.scss"
import ReviewForm from "../Review/ReviewForm/ReviewForm"

const Reviews = ({
  list,
}: {
  list: {
    id: number
    title: string
    text: string
    img: string
    rating?: number
    created_at: string
    userName: string
    hasReview: boolean
  }[]
}) => {
  const listOutput = useMemo(() => {
    if (!list) return
    const output: {
      date: string
      list: {
        id: number
        created_at: string
        title: string
        text: string
        img: string
        rating?: number
        userName: string
        hasReview: boolean
      }[]
    }[] = []
    list.map((item) => {
      const today = moment().locale("ru").format("DD MMMM YYYY")
      const itemDate = moment(item.created_at).locale("ru").format("DD MMMM YYYY")
      const date = today === itemDate ? "Сегодня" : itemDate
      const itemIndex = output.findIndex((i) => i.date === date)
      if (itemIndex >= 0) {
        output[itemIndex].list.push(item)
      } else {
        output.push({
          date: date,
          list: [item],
        })
      }
    })
    if (!output) return null
    return output.map((item, index) => {
      return (
        <div key={index} className={styles.block}>
          <h4 className={styles.title}>{item.date}</h4>
          <div>
            {item.list.map((review) => {
              if (!review.hasReview) return <ReviewForm className={styles.item} key={review.id} {...review} />
              return <Review key={review.id} {...review} className={styles.item} />
            })}
          </div>
        </div>
      )
    })
  }, [list])

  return <div>{listOutput}</div>
}

export default Reviews
