import React, { useEffect } from "react"
import styles from "./ServicesList.module.scss"
import ServiceEl from "./ServiceEl"
import NoFindBtn from "../NoFindBtn/NoFindBtn"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { languageModalOpen } from "../../redux/slice/languageModal"
import { useAppSelector } from "../../hooks"
import { selectAuth, selectIsAllowProposeNewServiceMain, updateIsAllowNewServiceCategory } from "../../redux/slice/auth"
import moment from "moment"
import { langWithCurrentMomentCode, servicesBackgroundListDesktop } from "../../utils/helpers"
import useWindowSize from "../../hooks/useWindowSize"

interface Props {
  setAuthModal?: any
  setAuthModalType?: any
}

const ServicesList: React.FC<Props> = ({ setAuthModal, setAuthModalType }) => {
  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  const dispatch = useDispatch()
  const currentLang = localStorage.getItem("i18nextLng")

  useEffect(() => {
    if (currentLang) {
      moment.locale(langWithCurrentMomentCode.get(currentLang))
    } else {
      moment.locale("en")
    }
  }, [currentLang])

  useEffect(() => {
    dispatch(updateIsAllowNewServiceCategory(false))
  }, [])

  const { serviceList, serviceListLoading } = useAppSelector((state) => state.main)

  const isAllowProposeNewServiceMain = useAppSelector(selectIsAllowProposeNewServiceMain)
  const { isDesktop } = useWindowSize()

  return (
    <div className={styles.serviceList}>
      {isDesktop && (
        <h4
          className={`${styles.serviceList__title} ${
            serviceListLoading ? `selectBack ${styles.serviceList__title_load}` : ""
          }`}
        >
          {t("services")}
        </h4>
      )}

      <div className={styles.serviceList__list}>
        {serviceListLoading && !serviceList.length ? (
          <>
            <div className={`${styles["serviceList__list-top"]}`}>
              <div className={`selectBack ${styles.serviceEl} ${styles.serviceEl__big}`} />
              <div className={`selectBack ${styles.serviceEl}`} />
              <div className={`selectBack ${styles.serviceEl}`} />
            </div>
            <div className={`${styles["serviceList__list-other"]}`}>
              <div className={`selectBack ${styles.serviceEl}`} />
              <div className={`selectBack ${styles.serviceEl}`} />
              <div className={`selectBack ${styles.serviceEl}`} />
            </div>
          </>
        ) : (
          <div className={styles.grid}>
            {serviceList.map((el: any, ind: number) => {
              return (
                <ServiceEl
                  key={ind}
                  id={el.id}
                  size={!ind ? "wide" : undefined}
                  title={el?.name}
                  subtitle={el?.description}
                  // background={isDesktop ? servicesBackgroundListDesktop[ind % 10] : servicesBackgroundList[ind % 10]}
                  background={servicesBackgroundListDesktop[ind % 10]}
                  img={el?.image_id}
                />
              )
            })}
          </div>
        )}

        {/* <ServiceEl dpClass={"two"} />
                <ServiceEl dpClass={"three"} />
                <ServiceEl dpClass={"four"} />
                <ServiceEl dpClass={"five"} />
                <ServiceEl dpClass={"six"} /> */}
      </div>

      {isAllowProposeNewServiceMain && <NoFindBtn size={"big"} isLoading={serviceListLoading} />}

      {!isDesktop && <NoFindBtn isLoading={serviceListLoading} />}
    </div>
  )
}

export default ServicesList
