import { FC, ReactNode, useState } from "react"
import styles from "./Accordion.module.scss"
import clsx from "clsx"
import { ArrowDownIcon } from "../../../icons/ArrowDownIcon"

interface Props {
  header: string
  children: ReactNode
  className?: string
}

const Accordion: FC<Props> = ({ header, children, className }) => {
  const [isOpen, setOpen] = useState<boolean>(true)

  const toggleContent = () => {
    setOpen((prev) => !prev)
  }

  return (
    <div className={clsx(isOpen && styles["accordion--is-open"], className)}>
      <button type="button" className={styles.header} onClick={toggleContent}>
        {header}
        <span className={styles.iconWrap}>
          <ArrowDownIcon />
        </span>
      </button>
      <div className={styles.main}>{children}</div>
    </div>
  )
}

export default Accordion
