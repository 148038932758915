import CountBtn from "../Assets/CountBtn/CountBtn"
import Button from "../Assets/Button/Button"
import Label from "../Assets/Label/Label"
import clsx from "clsx"
import Paper from "../Assets/Paper/Paper"
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { timeUnitsForLang } from "../../utils/helpers"
import { useFormikContext } from "formik"
import OptionsList from "../Modals/OrderModal/OptionsList"
import SelectObject from "../SelectObject/SelectObject"
import { IOrder } from "../../types/orderTypes"
import styles from "./OrderService.module.scss"

interface Props {
  data: IOrder
  setValidateIds: Dispatch<SetStateAction<any[]>>
  isLoadingSubmit?: boolean
}

const OrderServiceForm = ({ data, isLoadingSubmit, setValidateIds }: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  const currentLang = localStorage.getItem("i18nextLng") || "en"

  const ref = useRef<HTMLDivElement>(null)

  const [currentTimeUnit, setCurrentTimeUnit] = useState("")
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    setCurrentTimeUnit(() => {
      if (data?.execution_time_units && currentLang) {
        return timeUnitsForLang[(currentLang as "ru" | "en" | "th") ?? "en"][
          data.execution_time_units as "minutes" | "hours" | "days"
        ]
      } else return timeUnitsForLang["en"]["minutes"]
    })
  }, [data?.execution_time_units])

  const setErrorHandler = (val: boolean) => {
    setIsError(val)
  }

  const { isValid, setFieldValue, setFieldTouched, isSubmitting, errors, isValidating } = useFormikContext<{
    [key: string]: any
  }>()

  useEffect(() => {
    // Проскролливать при наличии ошибок и окончании сабмиттинга и валидатинга
    if (Object.keys(errors)?.length && !isValidating && !isSubmitting) {
      const errorsEls = ref.current?.querySelector(".error")
      errorsEls?.scrollIntoView({ block: "center", behavior: "smooth" })
    }
  }, [isSubmitting, ref])

  return (
    <>
      <Paper
        className={styles.main}
        footer={
          data?.service_action_name && (
            <>
              <div className={styles.footer}>
                {data?.is_multiorder && (
                  <CountBtn
                    onChange={(num) => {
                      void setFieldValue("count", num)
                    }}
                  />
                )}
                <Button
                  isLoading={isLoadingSubmit}
                  className={styles.submit}
                  txt={data?.service_action_name || t("order")}
                  disabled={!isValid || isError || isLoadingSubmit}
                  type={"submit"}
                />
              </div>
            </>
          )
        }
      >
        <div ref={ref}>
          <header className={styles.header}>
            <h2 className={styles.title}>{data?.name}</h2>
            {data?.execution_time_from || data?.execution_time_to ? (
              <Label
                txt={(() => {
                  if (data?.execution_time_from && data?.execution_time_to) {
                    return `${data.execution_time_from}-${data.execution_time_to} ${currentTimeUnit}`
                  } else if (data.execution_time_from && !data?.execution_time_to) {
                    return `${t("from")} ${data.execution_time_from} ${currentTimeUnit}`
                  } else if (!data?.execution_time_from && data?.execution_time_to) {
                    return `${t("to")} ${data.execution_time_to} ${currentTimeUnit}`
                  } else {
                    return ""
                  }
                })()}
                className={clsx(styles.productItemNew__time)}
                mode={"orange"}
                size={"sm"}
              />
            ) : null}
          </header>
          {data?.description && (
            <p className={styles.description} dangerouslySetInnerHTML={{ __html: data?.description }} />
          )}
          {data?.technicalObjects && (
            <SelectObject
              className={styles.selectObject}
              data={data}
              callbackSubmit={(el) => {
                if (!el) return
                void setFieldTouched("technical_object_id")
                void setFieldValue("technical_object_id", el.id)
              }}
            />
          )}
          {data?.options && data?.options?.length > 0 && (
            <OptionsList optionsItems={data.options} setError={setErrorHandler} setValidateIds={setValidateIds} />
          )}
        </div>
      </Paper>
    </>
  )
}

export default OrderServiceForm
