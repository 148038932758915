import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation } from "swiper/modules"
import { SwiperOptions } from "swiper/types/swiper-options"
import clsx from "clsx"
import SliderNav from "../Assets/SliderNav/SliderNav"

import "swiper/css"

import styles from "./ImportantEvents.module.scss"
import useWindowSize from "../../hooks/useWindowSize"
import { useMemo } from "react"

const list = [
  {
    txt: "Отключение VPN c 19 до 23",
    img: "/img/important-events/important-events-1.jpg",
    isActive: true,
  },
  {
    txt: "Заявление на отпуск",
    img: "/img/important-events/important-events-2.jpg",
    isActive: true,
  },
  {
    txt: "Заявление на отпуск2",
    img: "/img/important-events/important-events-2.jpg",
    isActive: true,
  },
  {
    txt: "Произошло происшествие",
    img: "/img/important-events/important-events-3.jpg",
  },
  {
    txt: "Произошло происшествие2",
    img: "/img/important-events/important-events-3.jpg",
  },
  {
    txt: "Произошло происшествие3",
    img: "/img/important-events/important-events-1.jpg",
  },
  {
    txt: "Произошло происшествие4",
    img: "/img/important-events/important-events-2.jpg",
  },
]

const ImportantEvents = () => {
  const { isDesktop } = useWindowSize()

  const swiperParams: SwiperOptions = useMemo(() => {
    return {
      slidesPerView: isDesktop ? 5 : 3,
      spaceBetween: isDesktop ? 15 : 8,
      modules: [Navigation],
      navigation: {
        prevEl: `.${styles.nav} .slider-prev-btn`,
        nextEl: `.${styles.nav} .slider-next-btn`,
      },
    }
  }, [isDesktop])

  return (
    <section className={styles.wrap}>
      <Swiper {...swiperParams} className={styles.slider}>
        {list.map((slide, index) => {
          return (
            <SwiperSlide key={index} className={clsx(styles.slide, slide.isActive && styles["slide--is-active"])}>
              <div className={styles.slide__inner} style={{ backgroundImage: `url(${slide.img})` }}>
                <h5 className={styles.slide__title}>{slide.txt}</h5>
              </div>
            </SwiperSlide>
          )
        })}
      </Swiper>
      <SliderNav className={styles.nav} />
    </section>
  )
}

export default ImportantEvents
