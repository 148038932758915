import { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from "react"
import { useLazyGetServiceOrderQuery, useLazyGetServiceOrderReviewsQuery } from "../../../redux/api/content"
import { selectOpenOrder, setOpenOrder } from "../../../redux/slice/ordersList"
import { addAllMessage, setAllMessage } from "../../../redux/slice/main"
import { useDispatch } from "react-redux"
import Chat from "../../Chat/Chat"
import { useAppSelector } from "../../../hooks"
import clsx from "clsx"
import { getApiUrl } from "../../../utils/getApiUrl"
import { useTranslation } from "react-i18next"
import OrderCallbackModal from "./OrderCallbackModal"
import Button from "../../Assets/Button/Button"
import ModalPortal from "../../Assets/ModalPortal/ModalPortal"
import { useScrollBlock } from "../../../hooks/useScrollBlock"
import OrderDescription from "../../OrderDescription/OrderDescription"
import { useLazyGetChatMessagesQuery } from "../../../redux/api/chat"
import styles from "./OrderInfoModal.module.scss"

interface Props {
  id: string
  chatID: string
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  delFromList?: (orderId: string) => void
  layout?: "history" | "active"
}

const OrderInfoModal: FC<Props> = ({ id, chatID, open, setOpen, delFromList = () => null, layout }) => {
  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  const dispatch = useDispatch()
  const isFirstLoadMess = useRef<any>(false)
  const { allowScroll } = useScrollBlock()

  // TODO: remove
  const openOrder = useAppSelector(selectOpenOrder)

  const [getServiceOrder, { data: orderInfo }] = useLazyGetServiceOrderQuery()
  //TODO: зачем? осталось от мобильной версии?
  const [getServiceOrderReviews, { data: orderReview }] = useLazyGetServiceOrderReviewsQuery()
  const [getChatMessages] = useLazyGetChatMessagesQuery()

  const [loading, setLoading] = useState<boolean>(true)
  const [loadingChat, setLoadingChat] = useState<boolean>(true)
  const [isCanceledModal, setCanceledModal] = useState<boolean>(false)

  useEffect(() => {
    if (typeof window.Echo === "undefined" || !chatID) return
    const channel = window.Echo.join(`chats.${chatID}`)
    channel.listen(".client-addMessage", (event: any) => {
      dispatch(addAllMessage(event))
    })
  }, [open, chatID])

  useEffect(() => {
    if (!id) return
    getServiceOrder(id)
      .then((resp) => {
        setLoading(false)
        if (resp?.data?.chat_dialog_id) {
          getChatMessages(resp.data.chat_dialog_id)
            .then((res: any) => {
              isFirstLoadMess.current = true
              dispatch(setAllMessage(res.data.aMessages))
              setLoadingChat(false)
            })
            .catch((er) => console.log(er.message))
        }
      })
      .catch((err) => console.warn(err))
    getServiceOrderReviews({ service_order_id: id })
  }, [id])

  return (
    <>
      <ModalPortal
        isOpen={open}
        setIsOpen={setOpen}
        header={
          <>
            {!loading && orderInfo?.photos?.[0] && (
              <img className={styles.img} src={getApiUrl() + "/public/photo/preview/" + orderInfo.photos[0]} alt="" />
            )}

            <h2 className={clsx("modal__title", styles.title, loading && "skeletonBlock")}>
              {orderInfo?.name} {orderInfo?.count && orderInfo.count > 1 ? <span>x{orderInfo.count}</span> : ""}
            </h2>
          </>
        }
        bodyNoPadding
      >
        <div className={styles.main}>
          <aside className={styles.aside}>
            {orderInfo && (
              <OrderDescription isLoading={loading} order={orderInfo} review={orderReview} layout={layout} />
            )}

            <footer className={styles.footer}>
              <Button
                txt={layout === "active" ? t("cancel") : "Вернуть в работу"}
                mode={"warning"}
                size={"sm"}
                onClick={() => {
                  setCanceledModal(true)
                }}
              />
            </footer>
          </aside>
          {loadingChat ? (
            <div className={clsx(styles.chatLoader, "skeletonBlock")} />
          ) : (
            <Chat
              chatID={chatID}
              orderInfo={orderInfo}
              isFirstLoadMess={isFirstLoadMess.current}
              emptyTxt={
                <>
                  Задайте свой вопрос по заявке
                  <br /> в чате со специалистом
                </>
              }
            />
          )}
        </div>
      </ModalPortal>

      {isCanceledModal && orderInfo?.id && (
        <OrderCallbackModal
          layout={layout}
          open={isCanceledModal}
          setOpen={setCanceledModal}
          orderId={orderInfo.id}
          delFromList={delFromList}
          callbackClose={() => {
            setOpen(false)
            allowScroll(true)
          }}
        />
      )}
    </>
  )
}

export default OrderInfoModal
