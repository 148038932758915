import { FC, ReactNode, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { selectActiveOrders } from "../../redux/slice/auth"
import { useAppSelector } from "../../hooks"
import LocalizedLink from "../../hoc/LocalizedLink"
import { useGetCountUnreadMessagesMutation, useGetQuantityOrderWithoutReviewMutation } from "../../redux/api/content"
import { useAppDispatch } from "../../hooks"
import {
  updateCountOrdersWithoutReview,
  countOrdersWithoutReview,
  countUnreadMessages,
  updateCountUnreadMessages,
} from "../../redux/slice/main"
import clsx from "clsx"
import { WorkListIcon } from "../../icons/WorkListIcon"
import { ArchiveListIcon } from "../../icons/ArchiveListIcon"
import { SendIcon } from "../../icons/SendIcon"
import { StarIconBorder } from "../../icons/StarIconBorder"
import { DoubleCheckIcon } from "../../icons/DoubleCheckIcon"
import { BookOpenIconStroke } from "../../icons/BookOpenIconStroke"
import { UserIcon } from "../../icons/UserIcon"
import { QRIcon } from "../../icons/QRIcon"
import styles from "./OrdersLine.module.scss"
import useWindowSize from "../../hooks/useWindowSize"
import OrdersLineLoader from "./OrdersLineLoader"
import MessagesModal from "../Modals/MessagesModal/MessagesModal"

interface Props {
  isLoading?: boolean
}

type listItem = {
  name: string
  icon: ReactNode
  link?: string
  txt?: string
  txtTrans?: string
  txtDesk?: string
  isSeparator?: boolean
  description?: string
  descriptionTrans?: string
  bg?: "red" | "yellow"
}

const list: listItem[] = [
  {
    name: "orders",
    link: "/orders",
    txtTrans: "requests",
    txtDesk: "заявки",
    descriptionTrans: "actRequests",
    icon: <WorkListIcon />,
  },
  {
    name: "history",
    link: "/history",
    txtTrans: "historyRequests",
    description: "Выполненные заявки",
    icon: <ArchiveListIcon />,
  },
  {
    name: "separator1",
    isSeparator: true,
    icon: <></>,
  },
  {
    name: "messages",
    txt: "Сообщения",
    description: "По вашим заявкам",
    icon: <SendIcon />,
    bg: "red",
  },
  {
    name: "reviews",
    link: "/reviews",
    txt: "Отзывы",
    description: "Нам важно ваше мнение",
    icon: <StarIconBorder />,
    bg: "yellow",
  },
  {
    name: "approvals",
    txt: "Согласования",
    description: "Требуется от вас",
    icon: <DoubleCheckIcon />,
  },
  {
    name: "employees",
    link: "/employees",
    txt: "Сотрудники",
    description: "База сотрудников ВВ",
    icon: <UserIcon />,
  },
  {
    name: "knowledge",
    link: "/articles",
    txt: "База знаний",
    description: "Самые полезные статьи",
    icon: <BookOpenIconStroke />,
  },
  {
    name: "qr",
    txt: "Сканировать QR",
    description: "Для быстрого перехода",
    icon: <QRIcon />,
  },
]

const OrdersLine: FC<Props> = ({ isLoading }) => {
  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  const activeOrders = useAppSelector(selectActiveOrders)

  const dispatch = useAppDispatch()
  const quantityOrdersWithoutReview = useAppSelector(countOrdersWithoutReview)
  const quantityCountUnreadMessages = useAppSelector(countUnreadMessages)
  const { isDesktop } = useWindowSize()

  const [getQuantityOrderWithoutReview] = useGetQuantityOrderWithoutReviewMutation()
  const [getCountUnreadMessages] = useGetCountUnreadMessagesMutation()

  const [isModalMesOpen, setModalMessOpen] = useState<boolean>(false)

  useEffect(() => {
    if (!isLoading) {
      getQuantityOrderWithoutReview().then((res: any) => {
        dispatch(updateCountOrdersWithoutReview(res))
      })
      getCountUnreadMessages().then((res: any) => {
        dispatch(updateCountUnreadMessages(res))
      })
    }
  }, [isLoading])

  return isLoading ? (
    isDesktop ? (
      <OrdersLineLoader />
    ) : (
      <div className={styles.ordersLine}>
        <div className={clsx(styles.item, styles["item--is-active"], "selectBack")} />
        <div className={clsx(styles.item, "selectBack")} />
      </div>
    )
  ) : (
    <>
      <div className={styles.ordersLine}>
        {list.map((item) => {
          if (item?.isSeparator) {
            return <hr key={item.name} className={styles.separator} />
          }
          const activeOrd =
            item.name === "orders" ? <span className={styles.item__activeCount}>{activeOrders}</span> : null
          const ordersNoRev =
            item.name === "orders"
              ? quantityCountUnreadMessages
              : item.name === "history"
              ? quantityOrdersWithoutReview
              : null
          if (item?.link) {
            return (
              <LocalizedLink
                key={item.name}
                to={item.link}
                className={clsx(styles.item, item.name === "orders" && styles["item--is-active"])}
              >
                <span className={clsx(styles.item__icon, item?.bg && styles["item__icon--" + item.bg])}>
                  {item.icon}
                </span>
                <span className={styles["item__txt-container"]}>
                  <span className={styles.item__txtWrap}>
                    {activeOrd}
                    {item?.txtTrans && (
                      <span className={clsx(item?.txtDesk && styles["item__txt--mob"])}>{t(item.txtTrans)}</span>
                    )}
                    {item?.txt && <span className={clsx(item?.txtDesk && styles["item__txt--mob"])}>{item.txt}</span>}
                    {/* {item?.txtDesk && <span className={clsx(styles["item__txt--desktop"])}>{item.txtDesk}</span>} */}
                  </span>
                  {(item?.description || item?.descriptionTrans) && (
                    <span className={styles.item__txtDescr}>
                      {item?.descriptionTrans ? t(item.descriptionTrans) : item.description}
                    </span>
                  )}
                </span>
                {ordersNoRev && ordersNoRev > 0 ? (
                  <span className={`${styles["item__no-rev"]}`}>{ordersNoRev}</span>
                ) : null}
              </LocalizedLink>
            )
          } else {
            return (
              <button
                key={item.name}
                className={clsx(styles.item)}
                onClick={() => {
                  if (item.name === "messages") {
                    setModalMessOpen(true)
                  }
                }}
              >
                <span className={clsx(styles.item__icon, item?.bg && styles["item__icon--" + item.bg])}>
                  {item.icon}
                </span>
                <span>
                  <span className={styles.item__txtWrap}>
                    <span>{item?.txt}</span>
                  </span>
                  <span className={styles.item__txtDescr}>{item?.description}</span>
                </span>
              </button>
            )
          }
        })}
      </div>
      {isModalMesOpen && <MessagesModal open={isModalMesOpen} setOpen={setModalMessOpen} />}
    </>
  )
}

export default OrdersLine
