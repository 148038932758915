import React from "react"
import styles from "./RadioRow.module.scss"
import clsx from "clsx"

interface Props {
  name: string
  title?: string
  value: any
  onChange?: any
  checked: any
  onClick?: any
  withChildrens?: boolean
  withChildrensFcn?: any
  className?: string
}

const RadioRow: React.FC<Props> = ({
  name,
  title,
  value,
  onChange,
  checked,
  onClick,
  withChildrens,
  withChildrensFcn,
  className,
}) => {
  return (
    <label
      className={clsx(styles.radioRow, className)}
      onClick={(e: any) => {
        if (withChildrens) {
          e.preventDefault()
          withChildrensFcn()
        }
      }}
    >
      <input
        type="radio"
        name={name}
        onChange={withChildrens ? () => null : onChange}
        value={value}
        readOnly
        checked={checked}
        onClick={onClick}
        hidden
      />
      <div className={styles.radioRow__itemIco} />
      <p className={styles.radioRow__itemText}>{title}</p>
    </label>
  )
}

export default RadioRow
