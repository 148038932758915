// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MainLayout_main-layout-search-block__e3b1y {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0 12.5px;
}
.MainLayout_main-layout-search-block__e3b1y .searchBlock {
  margin-top: 0;
}
.MainLayout_main-layout-search-block__QR-btn__uzI0I {
  display: flex;
  width: auto;
  height: auto;
  cursor: pointer;
}
.MainLayout_main-layout-search-block__QR-btn__uzI0I svg {
  width: 100%;
  height: 100%;
}
@media (min-width: 640px) {
  .MainLayout_main-layout-search-block__QR-btn__uzI0I {
    width: 40px;
    height: 40px;
  }
}
@media (min-width: 640px) {
  .MainLayout_main-layout-search-block__e3b1y {
    gap: 16px;
    margin-top: 16px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/MainLayout/MainLayout.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,QAAA;EACA,iBAAA;AACF;AAEI;EACE,aAAA;AAAN;AAIE;EACE,aAAA;EACA,WAAA;EACA,YAAA;EACA,eAAA;AAFJ;AAII;EACE,WAAA;EACA,YAAA;AAFN;AAKI;EAXF;IAYI,WAAA;IACA,YAAA;EAFJ;AACF;AAKE;EA7BF;IA8BI,SAAA;IACA,gBAAA;EAFF;AACF","sourcesContent":[".main-layout-search-block {\n  display: flex;\n  align-items: center;\n  gap: 8px;\n  padding: 0 12.5px;\n\n  :global {\n    .searchBlock {\n      margin-top: 0;\n    }\n  }\n\n  &__QR-btn {\n    display: flex;\n    width: auto;\n    height: auto;\n    cursor: pointer;\n\n    svg {\n      width: 100%;\n      height: 100%;\n    }\n\n    @media (min-width: 640px) {\n      width: 40px;\n      height: 40px;\n    }\n  }\n\n  @media (min-width: 640px) {\n    gap: 16px;\n    margin-top: 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main-layout-search-block": `MainLayout_main-layout-search-block__e3b1y`,
	"main-layout-search-block__QR-btn": `MainLayout_main-layout-search-block__QR-btn__uzI0I`
};
export default ___CSS_LOADER_EXPORT___;
